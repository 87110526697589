import { apiLinks } from '../../config/api-config';
import { rootApi } from './apiSlice';
import {
  PackageType,
  SearchDgItem,
  SearchDgItemsParams,
  DgItemDetail,
  GetDgItemDetailParams,
} from './fcpShipment.types';

const shipmentApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getAdrLanguages: builder.query<{ languageCodes: string[] }, void>({
      query: () => apiLinks.dangerousGoods + '/languages',
      keepUnusedDataFor: 60 * 60 * 4,
    }),
    getPackageTypes: builder.query<{ packageTypes: PackageType[] }, { languageCode: string }>({
      query: ({ languageCode }) => ({
        url: apiLinks.dangerousGoods + '/packageTypes',
        params: { languageCode },
      }),
      keepUnusedDataFor: 60 * 60 * 4,
    }),
    searchDgItems: builder.query<{ dgItems: SearchDgItem[] }, SearchDgItemsParams>({
      query: ({ unNumber, psnSubstring, languageCode }) => ({
        url: apiLinks.dangerousGoods + '/search',
        method: 'POST',
        body: {
          ...(unNumber && { unNumber: unNumber }),
          ...(psnSubstring && { psnSubstring: psnSubstring }),
          languageCode,
        },
      }),
    }),
    getDgItemDetail: builder.query<DgItemDetail, GetDgItemDetailParams>({
      query: ({ dgmId, languageCode, countryCode }) => ({
        url: apiLinks.dangerousGoods + '/detail',
        headers: { 'Country-Code': countryCode },
        params: { dgmId, languageCode },
      }),
      keepUnusedDataFor: 60 * 30,
    }),
  }),
});

export const { useGetAdrLanguagesQuery, useGetPackageTypesQuery, useLazySearchDgItemsQuery, useGetDgItemDetailQuery } =
  shipmentApi;
