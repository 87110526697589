import React, { Component } from 'react';
import { config } from '../../../../data-config';
import { Checkbox } from 'components';
import { Translation } from 'react-i18next';
import { euroconnectSelectedWithNoDeliveryDate } from '../../../ServiceDetails/ServiceDetails';
import AdditionalServicesOptions from '../AdditionalServicesOptions/AdditionalServicesOptions';
import { additionalServicesOptionsValidations } from 'globals/validators/AdditionalServices-validations';
import { combineWithFunctionality } from 'globals/utils/compareAndUpdate';
import { getTimeTable } from '../../DeliveryOptions-helpers';
import './AdditionalServiceRow.css';
import { setSelectedPickupDate } from '../../../../../store/portalOrderSlice';

export default class AdditionalServiceRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
    };
  }

  update = (params, context) => {
    const { adError, additionalServices } = additionalServicesOptionsValidations(
      JSON.parse(JSON.stringify(context.state.additionalServices)),
    );

    //update VAS only when all selected VAS doesnt have any error as 1A
    if (!adError || (!params.value && additionalServices[params.index].error)) {
      params.groupName = 'additionalServices';
      params.error = false;
      context.updateGroup(params);
    } else {
      context.updateState({ additionalServices });
    }
  };

  disableServiceIfLTCPresentedAndNoDates = () => {
    const groups = this.props.additionalServiceRow.groups;

    if (groups && groups.length > 0) {
      groups.forEach(group => {
        if (group.options) {
          group.options[0].forEach(option => {
            const isLTCDate = option.type === 'LTCdate';

            if (isLTCDate) {
              const { selectedProduct } = this.props.context.state;

              if (
                (selectedProduct && selectedProduct.LTCDates && selectedProduct.LTCDates.length === 0) ||
                this.props.ltcFailed
              ) {
                this.setState({ disabled: true });
              } else {
                this.setState({ disabled: false });
              }
            }
          });
        }
      });
    }
  };

  updatePriceForQuote = (context, additionalServiceRow, showOptionsSchema, params) => {
    const additionalServices = JSON.parse(JSON.stringify(context.state.additionalServices));

    if (showOptionsSchema) {
      const vasValidation = additionalServicesOptionsValidations(additionalServices);

      // when vas unselected
      if (!vasValidation.adError || !additionalServiceRow.value || additionalServiceRow.canMakeQuotePriceCall) {
        if (params.name === 'collectionAtTerminal' && !params.value) {
          getTimeTable(context.state.pickupDate.value, context, false, true, true, setSelectedPickupDate);
        }

        this.props.getQuoteForPriceWithAd(additionalServiceRow);
      }
    } else {
      this.props.getQuoteForPriceWithAd(additionalServiceRow);
    }
  };

  componentDidUpdate(prevProps) {
    const groups = this.props.additionalServiceRow.groups;

    if (groups && groups.length > 0) {
      groups.forEach(group => {
        if (group && group.options) {
          group.options.forEach(option => {
            const isLTCDatePresent = option.type === 'LTCdate';

            if (
              isLTCDatePresent &&
              JSON.stringify(this.props.context.state.matchedProducts) !==
                JSON.stringify(prevProps.context.state.matchedProducts)
            ) {
              this.disableServiceIfLTCPresentedAndNoDates();
            }
          });
        }
      });
    }
  }

  componentDidMount = () => {
    this.disableServiceIfLTCPresentedAndNoDates();
  };

  render() {
    const { additionalServiceRow, context } = this.props;

    const showOptionsSchema =
      additionalServiceRow.optionNotToHide && additionalServiceRow.groups && additionalServiceRow.groups.length > 0;

    const disabled =
      euroconnectSelectedWithNoDeliveryDate(context.state) &&
      config.vasExcludedForFailedLTC.indexOf(this.props.additionalServiceRow.code) !== -1;

    const VASAfterUpdate = async (contextFn, params) => {
      combineWithFunctionality(contextFn, params, config);
      this.updatePriceForQuote(contextFn, this.props.additionalServiceRow, showOptionsSchema, params);
    };

    let show = true;

    if (
      this.props.additionalServiceRow.code === 'doorstepDelivery' ||
      this.props.additionalServiceRow.code === 'deliveryWithIdControl'
    ) {
      show = this.props.context.state.doorStepVASAvailable;
    }

    return (
      show && (
        <>
          <Translation>
            {t => (
              <div className={this.props.className}>
                <div className="frc__additional-services-row--wrapper frc__column-wrapper l-grid--w-100pc-s">
                  <Checkbox
                    label={t(`additionalServices|${this.props.additionalServiceRow.code}|name`)}
                    name={this.props.additionalServiceRow.code}
                    deselect={this.props.additionalServiceRow.notCompatibleWith}
                    index={this.props.index}
                    isRequired={false}
                    disabled={this.props.additionalServiceRow.disabled || this.state.disabled || disabled || false}
                    checked={this.props.additionalServiceRow.value}
                    config={this.props.config}
                    CONST={this.props.CONST}
                    secondayText={
                      additionalServiceRow.calculatedResult
                        ? additionalServiceRow.calculatedResult
                        : additionalServiceRow.value === true && // is checked +
                          !additionalServiceRow.calculatedResult && // no calculated result +
                          context.state.quotePriceError === true // failed QfP call
                        ? t('general|errors|No price available online') // show this message
                        : null
                    }
                    context={this.props.context}
                    tooltip={t(`additionalServices|${this.props.additionalServiceRow.code}|information`)}
                    updateOnParent={(params, contextFn) => this.update(params, contextFn)}
                    afterUpdate={(contextFn, params) => VASAfterUpdate(contextFn, params)}
                  />
                </div>
                {this.props.additionalServiceRow.value &&
                  this.props.additionalServiceRow.optionNotToHide &&
                  this.props.additionalServiceRow.groups &&
                  this.props.additionalServiceRow.groups.length > 0 && (
                    <AdditionalServicesOptions
                      getQuoteForPriceWithAd={() => this.props.getQuoteForPriceWithAd(additionalServiceRow)}
                      deliveryMontoringClass={this.props.deliveryMontoringClass}
                      serviceIndex={this.props.index}
                      groups={this.props.additionalServiceRow.groups}
                      context={this.props.context}
                      errors={this.props.additionalServiceRow.errors}
                      config={this.props.config}
                      CONST={{
                        API_DOMAIN: 'additionalServices',
                      }}
                      code={this.props.additionalServiceRow.code}
                      disableOptionsSchemCall={this.props.additionalServiceRow.canMakeQuotePriceCall}
                      loaderRef={this.props.loaderRef}
                    />
                  )}
              </div>
            )}
          </Translation>
        </>
      )
    );
  }
}
