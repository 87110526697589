import { ExpandMore } from '@mui/icons-material';
import { Autocomplete, Box, createFilterOptions, InputLabel, TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

export const SelectAccount = ({
  label,
  onChange,
  value,
  placeholder,
  id,
  disabled,
  required,
  error,
  contextAdapter,
  preventAccountPreSelection,
}) => {
  const [reset, setReset] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const accountsWithProducts = useSelector(state => state.portalOrder.accountsWithProducts);

  const getOptionLabel = account => {
    return `${account.accountNumber}${account.accountReference ? ` (${account.accountReference})` : ``}`;
  };

  const options = accountsWithProducts.map(account => {
    if (value === account.accountNumber && account.disabled) {
      // reset the value of the account because the option is disabled
      contextAdapter('');
    }
    return {
      label: getOptionLabel(account),
      accountNumber: account.accountNumber,
      accountReference: account.accountReference,
      disabled: account.disabled,
    };
  });

  useEffect(() => {
    const enabledOptions = options.filter(option => !option.disabled);
    if (enabledOptions.length === 1 && !value && !preventAccountPreSelection) {
      contextAdapter(enabledOptions[0].accountNumber);
    }
  }, [value, options, contextAdapter, preventAccountPreSelection]);

  const autoCompleteOptions = useMemo(() => {
    if (options.length) {
      return [
        {
          accountNumber: undefined,
          label: placeholder,
          disabled: false,
        },
        ...options,
      ];
    }
    return [];
  }, [options, placeholder]);

  const autoCompleteDisabled = disabled || options.length === 0;

  const handleInputChange = (event, newInputValue) => {
    const match = autoCompleteOptions.find(option => {
      return option.label && option.label.toLowerCase().startsWith(newInputValue.toLowerCase());
    });

    if (match || newInputValue === '') {
      setInputValue(newInputValue);
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: option => (option.accountNumber ? option.accountNumber : ''),
  });

  return (
    <Box>
      <InputLabel htmlFor={`${id}Slt`} disabled={autoCompleteDisabled}>
        {label}
        {required ? '*' : null}
      </InputLabel>
      <Autocomplete
        disabled={autoCompleteDisabled}
        id={`${id}Slt`}
        disablePortal
        clearIcon={null}
        options={autoCompleteOptions}
        filterOptions={filterOptions}
        key={reset ? 'reset' : 'not-reset'}
        value={autoCompleteOptions.find(option => option.accountNumber === value && value !== undefined) || null}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        isOptionEqualToValue={(option, value) => option.accountNumber === value.accountNumber}
        sx={{
          '&.MuiAutocomplete-hasPopupIcon .MuiInputBase-root, &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root':
            {
              pr: '32px',
            },
          '& .MuiAutocomplete-popupIndicator': {
            height: '34px',
          },
        }}
        renderInput={params => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            error={error}
            hiddenLabel
            aria-label={label}
            placeholder={placeholder}
            required={required}
            sx={{
              '.MuiInputBase-root': {
                height: 48,
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
            }}
          />
        )}
        getOptionDisabled={option => option.disabled}
        popupIcon={
          <ExpandMore
            color={autoCompleteDisabled ? 'grey' : 'primary'}
            sx={{ width: '30px', height: '20px', transform: 'scale(1.6, 1)' }}
          />
        }
        onChange={(_, option) => {
          onChange(option?.accountNumber);
          if (!option?.accountNumber) {
            setReset(prev => !prev);
          }
        }}
      />
    </Box>
  );
};
