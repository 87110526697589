import { apiLinks } from '../../config/api-config';
import { rootApi } from './apiSlice';
import { ProductDto } from './common.types';
import { TagTypes } from './apiSlice';

const productApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getProducts: builder.query<ProductDto, { countryCode: string }>({
      providesTags: [TagTypes.PRODUCT_API_TAG],
      query: ({ countryCode }) => ({
        url: apiLinks.getFCPAPIProducts,
        headers: {
          'Country-Code': countryCode,
        },
      }),
      keepUnusedDataFor: 60 * 30,
    }),
  }),
});

export const { useGetProductsQuery, useLazyGetProductsQuery } = productApi;
