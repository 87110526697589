import React from 'react';
import { globalConfig } from '../data-config-global';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { Input, Error } from '../../components';

/**
 * Poland specific.
 **/
const SentInformation = props => {
  const { context, componentConfig, componentConstants } = props;
  const { t } = useTranslation();

  return (
    <div className="frc__generic--field-wrapper">
      <h4 className="margin-bottom-1 frc__red-lined-title ">
        {t(`general|sentInformation`)}
        <span className="has-tooltip-label">
          <div className="frc__tooltip-title-container has-icon icon-information color-DHL-red c-fcp-quote--info-container frc__tooltip_title">
            <div className="frc__tooltip-container-flyout">{t(`general|sentInformationTooltip`)}</div>
          </div>
        </span>
      </h4>
      <div className="l-grid l-grid--w-100pc-s">
        <div className="frc__column-wrapper frc__shipment-row-margin-top l-grid--w-100pc-s-all l-grid--w-40pc-m-s l-grid--w-30pc-m-m ">
          <Input
            id={'sentReference' + props.index}
            className=" frc__generic--field-wrapper--no-top "
            label={t('general|labels|sentReference')}
            index={props.index}
            name="sentReference"
            isRequired={false}
            lengthCheck={[RegExp(`^.{${1},${globalConfig.shipmentInputs.sentInformationLength}}$`)]}
            cutTextLimit={globalConfig.shipmentInputs.sentInformationLength}
            value={context.state.sentReference.value}
            context={context}
            config={componentConfig}
            CONST={componentConstants}
          />
          {context.state.sentReference.error && (
            <Error
              name={'sentReference' + props.index}
              className="frc__input--error"
              message={t('general|errors|sentReference', {
                max: globalConfig.shipmentInputs.sentInformationLength,
              })}
            />
          )}
        </div>
        <div className="frc__column-wrapper frc__shipment-row-margin-top l-grid--w-100pc-s-all l-grid--w-40pc-m-s l-grid--w-30pc-m-m ">
          <Input
            id={'sentCarrierKey' + props.index}
            className=" frc__generic--field-wrapper--no-top "
            label={t('general|labels|sentCarrierKey')}
            index={props.index}
            name="sentCarrierKey"
            isRequired={false}
            lengthCheck={[RegExp(`^.{${1},${globalConfig.shipmentInputs.sentInformationLength}}$`)]}
            value={context.state.sentCarrierKey.value}
            context={context}
            config={componentConfig}
            CONST={componentConstants}
          />
          {context.state.sentCarrierKey.error && (
            <Error
              name={'sentCarrierKey' + props.index}
              className="frc__input--error"
              message={t('general|errors|sentCarrierKey', {
                max: globalConfig.shipmentInputs.sentInformationLength,
              })}
            />
          )}
        </div>
      </div>
    </div>
  );
};

SentInformation.propTypes = {
  context: PropTypes.any.isRequired,
  componentConfig: PropTypes.any.isRequired,
  componentConstants: PropTypes.any.isRequired,
};

export default SentInformation;
