import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { useTranslation, withTranslation } from 'react-i18next';
import SelectServices from './SelectServices/SelectServices';
import AdditionalServices from './AdditionalServices/AdditonalServices';
import {
  getQuoteForPriceWithAd,
  checkSubmit,
  applyDesiredPickupDates,
  setMarkupCookie,
  fillMarkupPriceFromCookie,
  applyCutOffOnPickupDates,
  getDeliveryDates,
} from './DeliveryOptions-helpers';
import { Translation } from 'react-i18next';
import { Error } from 'components';
import { config, CONST } from '../../data-config';
import { analytics } from 'globals/utils/analytics';
import { disableOtherTabs } from '../AppTabs/AppTabs-helpers';
import Markup from './Markup/Markup';
import { withQueryClient } from '../../../utils/withQueryClient';
import { countryConfigQueryKey } from '../../../hooks/api/useGetCountryConfig';
import PickupDate from '../../../portal-order/components/PickupDate/PickupDate';
import {
  pickupDatesSelector,
  productPickupDatesSelector,
  selectedPickupDateSelector,
  setPickupDates,
  setSelectedPickupDate,
} from '../../../store/quoteSlice';
import { useSelector } from 'react-redux';
import { useGetDeliveryDates } from '../../../hooks/api/useGetDeliveryDates';
import { trackGetQuote, TrackGetQuoteStep } from '../../helpers/journeyTracking';

const DeliveryOptions = props => {
  const loaderRef = useRef();
  const { selectedPickupDate } = useSelector(selectedPickupDateSelector);

  const [localState, setLocalState] = useState({
    // isDialogVisible: false,
    nextBtnLoader: false,
    countryConfig: undefined,
  });

  const { state } = props.context;
  const disableNextButton =
    state.goNextError ||
    state.additionalServicesError ||
    state.additionalServicesGeneralError ||
    !state.pickupDate.value;

  const onGoClick = async t => {
    const { state } = props.context;

    disableOtherTabs(props, true, [3], config.tabs.tab3);

    if (!(state.additionalServicesError || state.additionalServicesGeneralError)) {
      setLocalState(prev => ({ ...prev, nextBtnLoader: true }));
      // Validation on submit
      const checkPerformedSuccessfully = !(await checkSubmit(props.context, props.routeContext));

      if (checkPerformedSuccessfully) {
        setMarkupCookie(props.context.state);
        analytics('step 3', CONST.ANALYTICS);
        props.nextTab(config.tabs.tab4);
      } else {
        disableOtherTabs(props, false, [3], config.tabs.tab3);
        setLocalState(prev => ({ ...prev, nextBtnLoader: false }));
      }
    }
  };

  useEffect(() => {
    const countryConfig = props.queryClient.getQueryData([countryConfigQueryKey], {
      type: 'active',
      exact: false,
    });
    setLocalState(prev => ({ ...prev, countryConfig }));
    applyCutOffOnPickupDates(props, loaderRef.current, countryConfig);
    applyDesiredPickupDates(props);
    fillMarkupPriceFromCookie(props.context);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deliveryDatesPayload = {
    sender: {
      countryCode: props.routeContext.state.pickupCountry?.value?.toUpperCase(),
      postalCode: props.routeContext.state.pickupPostalCode?.value,
    },
    receiver: {
      countryCode: props.routeContext.state.deliveryCountry?.value?.toUpperCase(),
      postalCode: props.routeContext.state.deliveryPostalCode?.value,
    },
    pickupDate: selectedPickupDate,
    productCodes: props.context.state.matchedProducts.map(product => product.code),
  };

  const { data: deliveryDates } = useGetDeliveryDates({
    deliveryDatesPayload,
  });

  const {
    updateState,
    state: { matchedProducts, selectedProduct },
  } = props.context;

  const { t } = useTranslation();

  const preSelectedProduct = props.routeContext.state.productsRangeOne?.value === true;
  const VASLength = props.context.state.additionalServices?.length;

  useEffect(() => {
    const isTimeDefiniteLoadingChecked =
      props.context.state.additionalServices.find(VAS => VAS.code === 'timeDefiniteLoading')?.value === true;
    if (isTimeDefiniteLoadingChecked) {
      // TODO: jm - find a way to call getDeliveryDates when TDL changes the selectedPickupDate without losing the additionalServices
      // This works, but does not call the LTC
      return;
    }
    if (selectedPickupDate) {
      if ((preSelectedProduct && VASLength) || !preSelectedProduct) {
        getDeliveryDates(new Date(selectedPickupDate), props, t, loaderRef.current, true, localState.countryConfig);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPickupDate, preSelectedProduct, VASLength]);

  useEffect(() => {
    if (!deliveryDates) {
      return;
    }
    const newMatchedProducts = matchedProducts.map(product => {
      return {
        ...product,
        deliveryDate: deliveryDates[product.code]?.deliveryDate,
        LTCDates: deliveryDates[product.code]?.fixedDeliveryDates,
      };
    });
    if (JSON.stringify(newMatchedProducts) !== JSON.stringify(matchedProducts)) {
      const newSelectedProduct = selectedProduct.code
        ? {
            ...selectedProduct,
            deliveryDate: deliveryDates[selectedProduct.code]?.deliveryDate,
            LTCDates: deliveryDates[selectedProduct.code]?.fixedDeliveryDates,
          }
        : selectedProduct;
      updateState({
        matchedProducts: newMatchedProducts,
        selectedProduct: newSelectedProduct,
      });
    }
  }, [deliveryDates, updateState, matchedProducts, selectedProduct]);

  const eurapidLtcError =
    props.routeContext.state.productsRangeOne.value &&
    config.ltcEurapidProductCodes.includes(props.routeContext.state.selectedProduct?.value?.toUpperCase())
      ? state.matchedProducts.some(
          product => config.ltcEurapidProductCodes.includes(product.code.toString()) && product.disabled,
        )
      : false;

  const previousTrackingData = useRef();
  useEffect(() => {
    const trackingData = {
      matchedProducts: matchedProducts.map(product => ({
        account: product.account,
        code: product.code || undefined,
        price: product.price?.FreightCost?.value,
      })),
      selectedProductCode: selectedProduct?.code || null,
    };
    if (!_.isEqual(trackingData, previousTrackingData.current)) {
      trackGetQuote(props.journeyId, TrackGetQuoteStep.DELIVERY_OPTIONS, trackingData);
    }
    previousTrackingData.current = trackingData;
  }, [props.journeyId, selectedProduct, matchedProducts]);

  return (
    <div className="frc__generic-section--wrapper">
      <Translation>
        {t => (
          <>
            <div className="margin-bottom-1">
              <PickupDate
                isReturnProduct={props.routeContext.state.pickupAddressResidential.value}
                contextUpdateField={props.context.updateField}
                contextUpdateState={props.context.updateState}
                contextPickupDateError={props.context.state.pickupDate.error}
                contextSelectedProduct={props.context.state.selectedProduct}
                contextMatchedProducts={props.context.state.matchedProducts}
                shipmentRange={props.context.state.shipmentRange?.value}
                payingAccountNumber={props.context.state.payingAccountNumber?.value}
                pickupMinDate={props.context.state.pickupMinDate}
                pickupDatesSelector={pickupDatesSelector}
                productPickupDatesSelector={productPickupDatesSelector}
                selectedPickupDateSelector={selectedPickupDateSelector}
                setPickupDates={setPickupDates}
                setSelectedPickupDate={setSelectedPickupDate}
              />

              <SelectServices
                eurapidLtcError={eurapidLtcError}
                routeContext={props.routeContext}
                shipmentContext={props.shipmentContext}
                context={props.context}
              />

              {!eurapidLtcError && (
                <AdditionalServices
                  getQuoteForPriceWithAd={additionalServiceRow => {
                    return getQuoteForPriceWithAd(props, additionalServiceRow, t, localState.countryConfig);
                  }}
                  routeContext={props.routeContext}
                  shipmentContext={props.shipmentContext}
                  context={props.context}
                />
              )}

              {props.context.state.selectedProduct &&
                !state.additionalServicesError &&
                props.context.state.selectedProduct.code &&
                !eurapidLtcError && (
                  <Markup
                    context={props.context}
                    getQuoteForPriceWithAd={additionalServiceRow => {
                      return getQuoteForPriceWithAd(props, additionalServiceRow, t, localState.countryConfig);
                    }}
                  />
                )}
            </div>

            <div className="frc__generic-row--wrapper">
              <div className="l-grid l-grid--w-100pc-s l-grid--between-s">
                <button
                  id="backToShipmentDetailsBtn"
                  className={`base-button base-button--white l-grid--w-25pc-w ${
                    localState.nextBtnLoader ? 'disabled' : ''
                  }`}
                  onClick={() => props.previousTab('tab2', 2)}
                >
                  <span>{t(`general|Back to Shipment Details`)}</span>
                </button>
                <button
                  id="goToReviewQuoteBtn"
                  className={`base-button base-button--wide l-grid--w-25pc-w 
                      ${localState.nextBtnLoader ? ' is-loading--right ' : ''}
                      ${disableNextButton || eurapidLtcError ? 'disabled' : ''}`}
                  disabled={disableNextButton || eurapidLtcError}
                  onClick={disableNextButton || eurapidLtcError ? null : () => onGoClick(t)}
                >
                  <span>{t(`quoteTool|Show Quote Details`)}</span>
                </button>
              </div>
              <div className="l-grid--right-s">
                {disableNextButton ? (
                  <Error
                    name="DeliveryOptionsMultiError"
                    message={
                      state.selectProductError || state.additionalServicesGeneralError || !state.pickupDate.value
                        ? t('general|errors|Please fill properly all necessary fields')
                        : t('general|errors|General error') +
                          '. <br> ' +
                          t('general|Try again or call technical support.') +
                          ' ' +
                          t('general|or') +
                          ' ' +
                          `<strong><a href="${t('pageLinks|chat')}" 
                                              class ="frc__chat-with-us"
                                              target="_blank" rel="noopener noreferrer">${t(
                                                `general|Chat with us`,
                                              )}</a></strong>` +
                          ' <br> ' +
                          t('general|ID') +
                          ': ' +
                          props.routeContext.state.transactionId
                    }
                  />
                ) : (
                  ''
                )}

                {eurapidLtcError && (
                  <Error name="eurapidLtcError" message={t(`quoteTool|cannot proceed without Delivery Date`)} />
                )}
              </div>
            </div>

            <div
              id="fcp-delivery-options-loader"
              ref={loaderRef}
              className={
                'frc__generic--loader-overlay' + (state.loaders.additionalServices.value ? ' is-loading ' : '')
              }
            >
              {' '}
            </div>
          </>
        )}
      </Translation>
    </div>
  );
};

const TranslatedDeliveryOptions = withTranslation()(DeliveryOptions);
export default withQueryClient(TranslatedDeliveryOptions);
