import React, { Component } from 'react';
import { CONST } from '../../../data-config';
import { apiLinks } from 'config/api-config';
import { Error } from 'components';
import { downloadApiRequest } from 'globals/utils/requests';
import { Translation } from 'react-i18next';
import { ModalViewEditList } from './ModalViewEditList';

import './ModalView.css';

class ReturnModalView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PDFdownloadError: false,
    };
  }

  downloadFile = async fileIndex => {
    if (fileIndex === 'noIndex') {
      return;
    }

    const report = this.props.returnShipment.reports[fileIndex];
    let url = '';
    if (report.name.includes(this.props.returnShipment.returnShipmentNumber)) {
      url = apiLinks.downloadDocumentPortal.replace('{UniqueCode}', this.props.returnShipment.returnShipmentUniqueCode);
    } else {
      url = apiLinks.downloadDocumentPortal.replace('{UniqueCode}', this.props.returnShipment.uniqueCode);
    }
    url = url.replace('{fileName}', report.name);

    await downloadApiRequest(url.toString(), 'GET').then(blob => {
      var file = new Blob([blob], {
        type: blob.type,
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
        return;
      }

      const urlU = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = urlU;
      link.setAttribute('download', `${report.name}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  render() {
    return (
      <Translation>
        {t => (
          <>
            <div className="frc__dialog">
              <div
                className="frc__dialog-overlay"
                onClick={this.props.loader ? null : this.props.onCloseModalAction}
              ></div>
              <div className="frc__modal-dialog-width frc__dialog-wrapper">
                <div className=" frc__dialog-container">
                  <div id="frc_dialog-close-btn" className="frc__dialog-close-button">
                    <button className="frc__button--delete--darker" onClick={this.props.onCloseModalAction}>
                      X
                    </button>
                  </div>
                  <h4 className="frc__modal-view-edit-center">{t(`general|${'Create return shipment'}`) + ' ?'}</h4>

                  {this.props.returnShipment == null && <ModalViewEditList shipment={this.props.shipment} />}

                  {this.props.returnShipment != null && (
                    <div className="margin-bottom-1 frc__modal-view-edit-list ">
                      <div className={` l-grid l-grid--w-100pc-s  `}>
                        <p>
                          {t('general|Your return shipment number is')}:
                          <strong> {this.props.returnShipment.returnShipmentNumber}</strong>
                          <br />
                          <span style={{ fontSize: '12px' }}>
                            *{t('general|Return Label can be printed by the Parcel Shop at drop off by the customer')}.
                          </span>
                        </p>
                      </div>
                      {this.props.returnShipment != null && this.props.returnShipment.reports.length >= 1 && (
                        <div className={` l-grid l-grid--w-100pc-s  `}>
                          <select
                            className="c-form--element-base c-form--element-select"
                            onChange={e => {
                              this.downloadFile(e.target.value);
                            }}
                          >
                            <option value="noIndex">{t('general|Select a file to download')}</option>
                            {this.props.returnShipment.reports.map((report, index) => {
                              return (
                                <option value={index} key={index}>
                                  {report.name}
                                </option>
                              );
                            })}
                          </select>
                          {this.state.PDFdownloadError && (
                            <Error
                              name="PDFdownloadError"
                              className="frc__input--error"
                              message={t(`palletPickup|Something has gone wrong with the download. Try again later.`)}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  <div className={` l-grid l-grid--w-100pc-s  `}>
                    <div className=" frc__modal-center l-grid--w-100pc-s l-grid--w-100pc-m-s l-grid--w-100pc-m-m">
                      <button
                        className={'base-button base-button--white l-grid--w-20pc-s frc__btn-margin-right '}
                        id="closeModalShipmentBtn"
                        onClick={this.props.onCloseModalAction}
                      >
                        {this.props.returnShipment == null
                          ? t('general|labels|buttons|Cancel')
                          : t('general|labels|buttons|Close')}
                      </button>

                      {this.props.returnShipment == null && (
                        <button
                          className={'base-button  l-grid--w-30pc-s '}
                          onClick={this.props.onCreateReturnShipment}
                          id={`${CONST.SAVE}ShipmentBtn`}
                        >
                          {t('general|labels|buttons|Create')}
                        </button>
                      )}
                    </div>
                  </div>

                  {this.props.error && (
                    <div className=" l-grid--w-100pc-s  ">
                      <Error
                        name="shipmentModalActionResultError"
                        className="frc__input--error"
                        message={
                          t('general|errors|General error') +
                          '<br>' +
                          t('general|Try again or call technical support.') +
                          ' ' +
                          t('general|or') +
                          ' ' +
                          `<strong><a href="${t('pageLinks|chat')}" 
                                    class ="frc__chat-with-us"
                                    target="_blank" rel="noopener noreferrer">${t(
                                      `general|Chat with us`,
                                    )}</a></strong>` +
                          ' <br> ' +
                          t('general|ID') +
                          ': ' +
                          this.props.transactionId
                        }
                      />
                    </div>
                  )}
                  <div className={`frc__generic--loader-overlay   ${this.props.loader ? 'is-loading' : ''}`} />
                </div>
              </div>
            </div>
          </>
        )}
      </Translation>
    );
  }
}

export default ReturnModalView;
