import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// for typescript to work tag types must be defined in the root API
// export const PRODUCT_API_TAG = 'Product';
export const TagTypes = {
  PRODUCT_API_TAG: 'Product',
  USER_API_TAG: 'User',
} as const;

export const rootApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
  }),
  endpoints: () => ({}),
  tagTypes: Object.values(TagTypes),
});

export const { usePrefetch } = rootApi;
