import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import Modal from 'react-modal';
import { Button } from 'components';

import './MessageModal.css';

const customStyles = {
  content: {
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    padding: '1rem 2rem',
    position: 'relative',
  },
};

export default class AccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      message: props.message || '',
      className: props.className,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(message = this.props.message, className = this.props.className) {
    this.setState({ isOpen: true, message, className });
  }

  closeModal() {
    this.setState({ isOpen: false });
  }

  render() {
    if (this.state.isOpen) {
      setTimeout(this.closeModal, 5000);
    }
    return (
      <div>
        <Translation>
          {t => (
            <>
              <Modal
                isOpen={this.state.isOpen}
                overlayClassName="frc-message-modal"
                style={customStyles}
                onRequestClose={this.closeModal}
                contentLabel="Modal"
              >
                <Button submit={this.closeModal} className="frc__button--delete--small" label="X" dataTestId="closeModalBtn"/>
                <div className="l-grid--center-s">
                  <h3 className={`frc-message-modal__message ${this.state.className}`} data-testid="messageModalText">{this.state.message}</h3>
                </div>
              </Modal>
            </>
          )}
        </Translation>
      </div>
    );
  }
}
