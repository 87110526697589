import { Autocomplete, Box, Button, InputLabel, Stack, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { globalConfig } from '../../../globals/data-config-global';
import { useGetUser } from '../../../hooks/api/useGetUser';
import { useCountryLanguage } from '../../../hooks/useCountryLanguage';
import { useGetDgItemDetailQuery, useGetPackageTypesQuery } from '../../../store/api/fcpShipment';
import { Loader } from '../Loader';
import { TextWithBoldPart } from '../TextWithBoldPart';
import { ControlledCheckbox, ControlledTextField } from '../form-inputs';
import {
  THREE_DECIMALS_INPUT_REGEX,
  THREE_DECIMALS_REGEX,
  TWO_DECIMALS_INPUT_REGEX,
  TWO_DECIMALS_REGEX,
} from '../../../utils/regexes';

export const transformDgApiToFormData = ({ dataFromApi, initialValues, packageTypesResponse, dgmId }) => {
  return {
    // NON EDITABLE FIELDS
    adrClass: dataFromApi?.class,
    unNumber: dataFromApi?.unNumber,
    packagingGroup: dataFromApi?.packingGroup,
    tunnelDescriptionCode: dataFromApi?.tunnelCode,
    properShippingName: dataFromApi?.psn,
    dgmId,
    quantityMeasurementUnitQualifier: dataFromApi?.kgl === 'L' ? 'LTR' : 'KGM',
    // BASE FIELDS - always shown
    grossWeight: initialValues?.grossWeight?.value ?? '',
    netQuantity: initialValues?.netQuantity?.value ?? '',
    numberOfUnits: initialValues?.numberOfUnits?.value ?? '',
    packageCode:
      packageTypesResponse?.packageTypes?.find(
        packageType => packageType.unCode === initialValues?.packageCode?.value,
      ) || null,
    environmentHazardous: initialValues?.environmentHazardous?.value ?? false,
    emptyContainer: initialValues?.emptyContainer?.value ?? false,
    waste: initialValues?.waste?.value ?? false,
    // DYNAMIC FIELDS (based on dgItemDetail)
    flamePoint: initialValues?.flamePoint?.value ?? '', // required if flashPointNeededImdg === true
    technicalDescription: initialValues?.technicalDescription?.value ?? '', // required if techChemNameNeededAdr === true
    limitedQuantity: initialValues?.limitedQuantity?.value ?? false, // allowed if limitedQuantityAllowed === true
    exceptedQuantity: initialValues?.exceptedQuantity?.value ?? false, // allowed if exceptedQuantityAllowed === true
    marinePollutant: initialValues?.marinePollutant?.value ?? false,
    marinePollutantName: initialValues?.marinePollutantName?.value ?? '', // required if marinePollutant === true
  };
};

export const DgItemDetail = ({
  dgmId,
  setDgmId,
  onDetailSubmit,
  onDetailError,
  initialValues,
  validateRequired,
  onDeleteItem,
  disableDelete,
  dgWeightError,
  hideErrors,
}) => {
  const { language: languageCode } = useCountryLanguage();
  const { data: userAccountCountryCode } = useGetUser({ select: data => data?.user?.accountCountryCode });
  const { data, isFetching, isError } = useGetDgItemDetailQuery(
    {
      dgmId: dgmId,
      languageCode: languageCode,
      countryCode: userAccountCountryCode?.toLowerCase(),
    },
    { skip: !dgmId },
  );

  const { data: packageTypesResponse, isLoading: packageTypesLoading } = useGetPackageTypesQuery({
    languageCode: languageCode,
  });

  const loading = isFetching || packageTypesLoading;

  const detailFormDefaults = transformDgApiToFormData({
    dataFromApi: data,
    initialValues,
    packageTypesResponse,
    dgmId,
  });

  // there is a toast notification on error
  if (isError) return null;
  // only render the form if the data is loaded so we can use it for defaultValues etc.
  return loading ? (
    <Loader />
  ) : (
    <DgItemDetailForm
      dgItemDetail={data}
      setDgmId={setDgmId}
      dgmId={dgmId}
      onDetailSubmit={onDetailSubmit}
      onDetailError={onDetailError}
      detailFormDefaults={detailFormDefaults}
      validateRequired={validateRequired}
      onDeleteItem={onDeleteItem}
      disableDelete={disableDelete}
      dgWeightError={dgWeightError}
      hideErrors={hideErrors}
    />
  );
};

const DgItemDetailForm = ({
  dgItemDetail,
  setDgmId,
  onDetailSubmit,
  onDetailError,
  detailFormDefaults,
  validateRequired,
  onDeleteItem,
  disableDelete,
  dgWeightError,
  dgmId,
  hideErrors,
}) => {
  const { t } = useTranslation();
  const { language: languageCode } = useCountryLanguage();
  const { data: packageTypesResponse, isLoading: packageTypesLoading } = useGetPackageTypesQuery({
    languageCode: languageCode,
  });

  const netQuantityMax = Number.isNaN(parseInt(dgItemDetail?.maxQuantity1136))
    ? null
    : parseInt(dgItemDetail?.maxQuantity1136);

  const {
    handleSubmit,
    control,
    watch,
    trigger,
    getValues,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: detailFormDefaults,
    mode: 'onChange',
  });
  const onSubmit = useCallback(
    data => {
      onDetailSubmit(data);
    },
    [onDetailSubmit],
  );

  const onError = useCallback(
    errors => {
      onDetailError(errors, getValues());
    },
    [getValues, onDetailError],
  );

  const errorMessage = useCallback(
    (tKey, maxLimit) =>
      t('general|Dangerous Good Error', {
        name: t(tKey),
        max: maxLimit,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, languageCode],
  );

  const limitedQuantityWatch = watch('limitedQuantity');
  const exceptedQuantityWatch = watch('exceptedQuantity');

  useEffect(
    () => {
      /**
       * Show the error messages right away because on portal order,
       * we need to see them when we click on the Next button
       */

      handleSubmit(onSubmit, onError)();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const grossWeightRequired = useMemo(() => {
    const required = limitedQuantityWatch && validateRequired;
    return required;
  }, [validateRequired, limitedQuantityWatch]);

  const numberOfUnitsRequired = useMemo(() => {
    return (exceptedQuantityWatch || (!limitedQuantityWatch && !exceptedQuantityWatch)) && validateRequired;
  }, [validateRequired, limitedQuantityWatch, exceptedQuantityWatch]);

  const netQuantityRequired = useMemo(() => {
    return !limitedQuantityWatch && !exceptedQuantityWatch && validateRequired;
  }, [validateRequired, limitedQuantityWatch, exceptedQuantityWatch]);

  const packageCodeRequired = useMemo(() => {
    return !limitedQuantityWatch && !exceptedQuantityWatch && validateRequired;
  }, [validateRequired, limitedQuantityWatch, exceptedQuantityWatch]);

  useEffect(() => {
    const subscription = watch(handleSubmit(onSubmit, onError));
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit, watch]);

  const validateGrossWeightNetQuantity = ({ grossWeight, netQuantity, netQuantityUnit, field }) => {
    if (grossWeight && netQuantityUnit?.toLowerCase() === 'kg') {
      const parsedGrossWeight = parseFloat(grossWeight.replace(/,/g, '.')); // Replace commas with decimals
      const parsedNetQuantity = parseFloat(netQuantity.replace(/,/g, '.'));
      if (parsedGrossWeight < parsedNetQuantity) {
        return t('general|dangerousGoods|grossWeightGreaterThanNetQuantity');
      }
    }
    if (
      ((grossWeight && field === 'grossWeight') ||
        (netQuantity && field === 'netQuantity' && netQuantityUnit?.toLowerCase() === 'kg' && !grossWeight)) &&
      dgWeightError
    ) {
      return t('general|dangerousGoods|dangerousGoodsExceedTotalWeight');
    }
    return true;
  };

  useEffect(() => {
    trigger(['grossWeight', 'netQuantity']);
  }, [dgWeightError, trigger]);

  useEffect(() => {
    if (
      !dgWeightError &&
      (errors?.netQuantity?.message === t('general|dangerousGoods|dangerousGoodsExceedTotalWeight') ||
        errors?.grossWeight?.message === t('general|dangerousGoods|dangerousGoodsExceedTotalWeight'))
    ) {
      handleSubmit(onSubmit, onError)();
    }
  }, [dgWeightError, errors, t, clearErrors, handleSubmit, onSubmit, onError]);

  return (
    <Stack spacing={2} id={`${dgmId}-dgItemDetail`}>
      <Box
        display="flex"
        justifyContent="space-between"
        gap={2}
        p={1}
        sx={{ border: '.1rem solid #949494', borderRadius: '.4rem', backgroundColor: 'background.section' }}
      >
        <Box display="flex" flexDirection="column" gap={2}>
          <Stack>
            <Typography variant="h4" fontSize="18px">
              <span id={`${dgmId}-dgItemDetailUnNumber`}>{`UN ${dgItemDetail?.unNumber}`}</span>{' '}
              <span id={`${dgmId}-dgItemDetailPsn`}>{`${dgItemDetail?.psn}`}</span>
            </Typography>
            <Typography variant="body">{dgItemDetail?.comment}</Typography>
          </Stack>
          <Stack direction="row" spacing={3}>
            <TextWithBoldPart
              normalText={`${t('portalOrder|ADR Class')}: `}
              boldText={<span id={`${dgmId}-dgItemDetailClass`}>{dgItemDetail?.class || '-'}</span>}
            />
            <TextWithBoldPart
              normalText={`${t('general|labels|inputs|Packaging Group')}: `}
              boldText={<span id={`${dgmId}-dgItemDetailPackingGroup`}>{dgItemDetail?.packingGroup || '-'}</span>}
            />
            <TextWithBoldPart
              normalText={`${t('general|Tunnel Description Code')}: `}
              boldText={<span id={`${dgmId}-dgItemDetailTunnelCode`}>{dgItemDetail?.tunnelCode || '-'}</span>}
            />
          </Stack>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              onDeleteItem();
            }}
            disabled={disableDelete}
            id={`${dgmId}-dgItemDetailRemove`}
          >
            {t('general|dangerousGoods|remove')}
          </Button>
          <Button variant="outlined" onClick={() => setDgmId(null)} id={`${dgmId}-dgItemDetailChange`}>
            {t('general|labels|buttons|change')}
          </Button>
        </Box>
      </Box>

      <Box component="form" noValidate>
        <Stack spacing={2}>
          {/* DYNAMIC FIELDS (based on dgItemDetail) */}
          <Typography variant="body2" fontWeight="bold">
            {t('general|dangerousGoods|additionalDetailsTitle')}
          </Typography>
          <Box
            display="flex"
            flexWrap="wrap"
            gap={4}
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'strech', md: 'flex-start' }}
          >
            <ControlledTextField
              control={control}
              label={t('general|Technical Description')}
              fieldName="technicalDescription"
              rules={{
                required:
                  validateRequired &&
                  dgItemDetail?.techChemNameNeededAdr &&
                  errorMessage('general|Technical Description', globalConfig.maxDescriptionLength),
                maxLength: {
                  value: globalConfig.maxDescriptionLength,
                  message: errorMessage('general|Technical Description', globalConfig.maxDescriptionLength),
                },
              }}
              TextFieldProps={{
                multiline: true,
                minRows: 2,
                maxRows: 2,
                sx: { minWidth: 400 },
                inputProps: {
                  style: {},
                  id: `${dgmId}-dgItemDetailTechnicalDescription`,
                  maxLength: globalConfig.maxDescriptionLength,
                },
              }}
              required={validateRequired && dgItemDetail?.techChemNameNeededAdr}
              hideErrors={hideErrors}
            />
            <ControlledTextField
              control={control}
              label={t('general|Flame Point')}
              fieldName="flamePoint"
              rules={{
                pattern: { value: TWO_DECIMALS_REGEX, message: t('general|labels|inputs|invalidFormat') },
                maxLength: {
                  value: globalConfig.dangerousGoodDimensions.max_flame_point,
                  message: errorMessage('general|Flame Point', globalConfig.dangerousGoodDimensions.max_flame_point),
                },
              }}
              inputRegex={TWO_DECIMALS_INPUT_REGEX}
              TextFieldProps={{
                inputProps: {
                  id: `${dgmId}-dgItemDetailFlamePoint`,
                  maxLength: globalConfig.dangerousGoodDimensions.max_flame_point,
                },
              }}
              hideErrors={hideErrors}
            />
            {/* CHECKBOXES */}
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} flexWrap="wrap" gap={2}>
              <ControlledCheckbox
                control={control}
                label={t('general|labels|inputs|environmentHazardous')}
                fieldName="environmentHazardous"
                CheckboxProps={{
                  inputProps: {
                    id: `${dgmId}-dgItemDetailEnvironmentHazardous`,
                  },
                }}
              />
              <ControlledCheckbox
                control={control}
                label={t('general|labels|inputs|Limited Quantity')}
                fieldName="limitedQuantity"
                CheckboxProps={{
                  inputProps: {
                    id: `${dgmId}-dgItemDetailLimitedQuantity`,
                  },
                }}
                disabled={!dgItemDetail?.limitedQuantityAllowed || exceptedQuantityWatch}
              />
              <ControlledCheckbox
                control={control}
                label={t('general|labels|inputs|exceptedQuantity')}
                fieldName="exceptedQuantity"
                CheckboxProps={{
                  inputProps: {
                    id: `${dgmId}-dgItemDetailExceptedQuantity`,
                  },
                }}
                disabled={!dgItemDetail?.exceptedQuantityAllowed || limitedQuantityWatch}
              />
              <ControlledCheckbox
                control={control}
                label={t('general|labels|inputs|emptyContainer')}
                CheckboxProps={{
                  inputProps: {
                    id: `${dgmId}-dgItemDetailEmptyContainer`,
                  },
                }}
                fieldName="emptyContainer"
              />
              <ControlledCheckbox
                control={control}
                label={t('general|labels|inputs|waste')}
                fieldName="waste"
                CheckboxProps={{
                  inputProps: {
                    id: `${dgmId}-dgItemDetailWaste`,
                  },
                }}
              />
              <Stack direction="row">
                <ControlledCheckbox
                  control={control}
                  label={t('general|labels|inputs|marinePollutant')}
                  fieldName="marinePollutant"
                  CheckboxProps={{
                    inputProps: {
                      id: `${dgmId}-dgItemDetailMarinePollutant`,
                    },
                  }}
                />
                {watch('marinePollutant') && (
                  <ControlledTextField
                    control={control}
                    label={t('general|labels|inputs|marinePollutantName')}
                    TextFieldProps={{
                      inputProps: {
                        id: `${dgmId}-dgItemDetailMarinePollutantName`,
                      },
                    }}
                    fieldName="marinePollutantName"
                    rules={{
                      required:
                        validateRequired &&
                        errorMessage(
                          'general|labels|inputs|marinePollutantName',
                          globalConfig.dangerousGoodDimensions.max_marine_pollutant_name,
                        ),
                      maxLength: {
                        value: globalConfig.dangerousGoodDimensions.max_marine_pollutant_name,
                        message: errorMessage(
                          'general|labels|inputs|marinePollutantName',
                          globalConfig.dangerousGoodDimensions.max_marine_pollutant_name,
                        ),
                      },
                    }}
                    required={validateRequired}
                    hideErrors={hideErrors}
                  />
                )}
              </Stack>
            </Box>
          </Box>
          {/* BASE FIELDS */}
          <Typography variant="body2" fontWeight="bold">
            {t('general|dangerousGoods|packageDetailsTitle')}
          </Typography>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            gap={4}
            alignItems={{ xs: 'strech', md: 'flex-start' }}
          >
            <ControlledTextField
              control={control}
              label={`${t('general|labels|metrics|Gross Weight')} (kg)`}
              fieldName="grossWeight"
              TextFieldProps={{
                inputProps: {
                  id: `${dgmId}-dgItemDetailGrossWeight`,
                  maxLength: globalConfig.dangerousGoodDimensions.max_gross_weight_or_net_quantity,
                },
              }}
              rules={{
                required:
                  grossWeightRequired &&
                  errorMessage(
                    'general|labels|metrics|Gross Weight',
                    globalConfig.dangerousGoodDimensions.max_gross_weight_or_net_quantity,
                  ),
                maxLength: {
                  value: globalConfig.dangerousGoodDimensions.max_gross_weight_or_net_quantity,
                  message: errorMessage(
                    'general|labels|metrics|Gross Weight',
                    globalConfig.dangerousGoodDimensions.max_gross_weight_or_net_quantity,
                  ),
                },
                pattern: {
                  value: THREE_DECIMALS_REGEX,
                  message: t('general|labels|inputs|invalidFormat'),
                },
                validate: (value, { netQuantity }) => {
                  const grossNetValidationResult = validateGrossWeightNetQuantity({
                    netQuantity,
                    grossWeight: value,
                    netQuantityUnit: dgItemDetail.kgl,
                    field: 'grossWeight',
                  });
                  return grossNetValidationResult;
                },
              }}
              inputRegex={THREE_DECIMALS_INPUT_REGEX}
              required={grossWeightRequired}
              hideErrors={hideErrors}
            />
            <ControlledTextField
              control={control}
              label={`${t('general|labels|metrics|Net Quantity')} (${dgItemDetail.kgl})`}
              fieldName="netQuantity"
              TextFieldProps={{
                inputProps: {
                  id: `${dgmId}-dgItemDetailNetQuantity`,
                  maxLength: globalConfig.dangerousGoodDimensions.max_gross_weight_or_net_quantity,
                },
              }}
              rules={{
                required: netQuantityRequired && t('general|labels|inputs|required'),
                maxLength: {
                  value: globalConfig.dangerousGoodDimensions.max_gross_weight_or_net_quantity,
                  message: errorMessage(
                    'general|labels|metrics|Net Quantity',
                    globalConfig.dangerousGoodDimensions.max_gross_weight_or_net_quantity,
                  ),
                },
                pattern: {
                  value: TWO_DECIMALS_REGEX,
                  message: t('general|labels|inputs|invalidFormat'),
                },
                validate: (value, { grossWeight }) => {
                  const grossNetValidationResult = validateGrossWeightNetQuantity({
                    grossWeight,
                    netQuantity: value,
                    netQuantityUnit: dgItemDetail.kgl,
                    field: 'netQuantity',
                  });

                  if (grossNetValidationResult !== true) {
                    return grossNetValidationResult;
                  }

                  if (
                    (!value && !netQuantityRequired) ||
                    (netQuantityMax && parseFloat(value.replace(',', '.')) <= netQuantityMax) ||
                    grossNetValidationResult === true
                  ) {
                    return true;
                  }

                  return (
                    netQuantityMax &&
                    t('general|errors|invalidValue', {
                      name: t('general|labels|metrics|Net Quantity'),
                      max: netQuantityMax,
                      min: 0,
                    })
                  );
                },
              }}
              required={netQuantityRequired}
              inputRegex={TWO_DECIMALS_INPUT_REGEX}
              hideErrors={hideErrors}
            />
            <ControlledTextField
              control={control}
              label={t('general|labels|inputs|Number Of Units')}
              fieldName="numberOfUnits"
              TextFieldProps={{
                inputProps: {
                  id: `${dgmId}-dgItemDetailNumberOfUnits`,
                  maxLength: globalConfig.dangerousGoodDimensions.max_no_of_units,
                },
              }}
              rules={{
                required:
                  numberOfUnitsRequired &&
                  errorMessage(
                    'general|labels|inputs|Number Of Units',
                    globalConfig.dangerousGoodDimensions.max_no_of_units,
                  ),
                maxLength: {
                  value: globalConfig.dangerousGoodDimensions.max_no_of_units,
                  message: errorMessage(
                    'general|labels|inputs|Number Of Units',
                    globalConfig.dangerousGoodDimensions.max_no_of_units,
                  ),
                },
              }}
              inputRegex={/^\d*$/}
              required={numberOfUnitsRequired}
              hideErrors={hideErrors}
            />
            <Box minWidth="200px">
              <Controller
                control={control}
                name={'packageCode'}
                rules={{
                  required: packageCodeRequired && t('general|labels|inputs|required'),
                }}
                render={({ field: { value, onChange, onBlur }, fieldState: { invalid, error, isTouched } }) => (
                  <Stack>
                    <InputLabel
                      sx={{ fontSize: '15px' }}
                      required={packageCodeRequired}
                      error={invalid && (!hideErrors || isTouched)}
                    >{`${t('general|labels|inputs|Package Code')}`}</InputLabel>{' '}
                    <Autocomplete
                      id={`${dgmId}-dgItemDetailPackageCode`}
                      required={packageCodeRequired}
                      fullWidth
                      loading={packageTypesLoading}
                      value={value}
                      options={packageTypesResponse?.packageTypes || []}
                      getOptionLabel={option => `${option.unCode} (${option.description})`}
                      isOptionEqualToValue={(option, value) => option?.unCode === value?.unCode}
                      renderOption={(props, option) => <li {...props}>{`${option.unCode} (${option.description})`}</li>}
                      componentsProps={{ popper: { style: { width: '600px' } } }}
                      onChange={(e, data) => onChange(data)}
                      sx={{
                        ' .MuiInputBase-input': {
                          height: '1.5rem',
                        },
                      }}
                      renderInput={params => (
                        <TextField
                          helperText={invalid && (!hideErrors || isTouched) && (error?.message || 'Invalid value')}
                          onBlur={onBlur}
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                )}
              />
            </Box>
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};
