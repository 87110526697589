import { apiRequest } from '../../globals';
import { apiLinks } from '../../config/api-config';

export enum TrackGetQuoteStep {
  ROUTE,
  SHIPMENT_DETAILS,
  DELIVERY_OPTIONS,
  REVIEW_QUOTE,
  PROCEED_TO_BOOKING,
}

export const trackGetQuote = (journeyId: string, step: TrackGetQuoteStep, data?: any) => {
  const payload = {
    journeyId: journeyId,
    step: TrackGetQuoteStep[step],
    data: data,
  };
  apiRequest(apiLinks.journeyTrackingGetQuote, 'POST', { body: payload }).finally(); // this is fire and forget, we are not interested in any result
};
