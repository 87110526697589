import { AUTHORIZATION } from '../../Login/constants';
import { apiLinks } from '../../config/api-config';
import { rootApi, TagTypes } from './apiSlice';
import { GetUserAccountsAndProductsResponse } from './fcpUser.types';

const userApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getUserAccountsAndProducts: builder.query<GetUserAccountsAndProductsResponse, void>({
      providesTags: [TagTypes.USER_API_TAG],
      query: () => ({
        url: apiLinks.getUsersAccountsAndProductsData,
        headers: {
          // todo: fix authorization header
          Authorization: localStorage.getItem(AUTHORIZATION) ?? '',
        },
      }),
      transformResponse: (response: GetUserAccountsAndProductsResponse, meta, arg) => {
        const productSpecifications = response.productSpecifications.map(product => {
          const productCode = Object.keys(product)[0];
          const productSpecification = productCode ? product[productCode] : undefined;
          return {
            ...productSpecification,
          };
        });
        const accountsWithProductsAndSpecs = response.accounts.map(account => {
          const products = account.products.map(product => {
            const productSpecification = productSpecifications.find(
              productSpec => productSpec.code === product.productCode,
            );
            return {
              ...product,
              productSpecification,
            };
          });
          return {
            ...account,
            products,
            atLeastOneProductB2C: products.some(product =>
              // todo: should be removed after we have correct API types
              product.productSpecification?.marketAvailability?.some((market: any) => market?.name?.toUpperCase() === 'B2C'),
            ),
          };
        });
        return {
          ...response,
          accountsWithProductsAndSpecs,
        };
      },
      keepUnusedDataFor: 60 * 30,
    }),
  }),
});

export const { useGetUserAccountsAndProductsQuery, useLazyGetUserAccountsAndProductsQuery } = userApi;
