import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from './store';
import { findFirstCommonDate } from '../utils/dates';

interface AccountWithProducts {
  productRangeOptions: { disabled: boolean }[];
  [key: string]: any;
}

interface PortalOrderState {
  // TODO: verify types once we start refactoring
  accountNumber: string | undefined;
  accountsWithProducts: AccountWithProducts[];
  enableResidentialFromAddress: boolean;
  enableResidentialToAddress: boolean;
  residentialAddressFromRequired: boolean;
  residentialAddressToRequired: boolean;
  pickupDates: string[];
  productPickupDates: Record<string, string[]>;
  selectedPickupDate: string | undefined;
}

const initialState: PortalOrderState = {
  // accounts and products:
  accountNumber: undefined,
  accountsWithProducts: [],

  // residential address:
  enableResidentialFromAddress: true,
  enableResidentialToAddress: true,
  residentialAddressFromRequired: false,
  residentialAddressToRequired: false,

  // pickup dates:
  pickupDates: [],
  productPickupDates: {},
  selectedPickupDate: undefined,
};

export const portalOrderSlice = createSlice({
  name: 'portalOrder',
  initialState,
  reducers: {
    setProductPickupDates: (state, action: PayloadAction<Record<string, string[]>>) => {
      state.productPickupDates = action.payload;
      state.pickupDates = [];
      state.selectedPickupDate = undefined;
    },
    setPickupDates: (state, action: PayloadAction<string[]>) => {
      state.pickupDates = action.payload;
    },
    setSelectedPickupDate: (state, action: PayloadAction<string | undefined>) => {
      state.selectedPickupDate = action.payload;
    },
    setAccountNumber: (state, action: PayloadAction<string | undefined>) => {
      state.accountNumber = action.payload;
    },
    setAccountsWithProducts: (state, action: PayloadAction<AccountWithProducts[]>) => {
      const accounts = action.payload;
      state.accountsWithProducts = accounts.map(account => {
        const disabled = account.productRangeOptions.every(productRangeOption => productRangeOption.disabled);
        return {
          ...account,
          disabled,
        };
      });
    },
    setEnableResidentialFromAddress: (state, action: PayloadAction<boolean>) => {
      state.enableResidentialFromAddress = action.payload;
    },
    setEnableResidentialToAddress: (state, action: PayloadAction<boolean>) => {
      state.enableResidentialToAddress = action.payload;
    },
    setResidentialAddressFromRequired: (state, action: PayloadAction<boolean>) => {
      state.residentialAddressFromRequired = action.payload;
    },
    setResidentialAddressToRequired: (state, action: PayloadAction<boolean>) => {
      state.residentialAddressToRequired = action.payload;
    },
    resetPortalOrderSlice: () => initialState,
  },
});

export const selectPortalOrder = (state: RootState) => state.portalOrder;
export const selectedPickupDateSelector = createSelector([selectPortalOrder], ({ selectedPickupDate }) => ({
  selectedPickupDate,
}));
export const pickupDatesSelector = createSelector([selectPortalOrder], ({ pickupDates }) => ({
  pickupDates,
}));
export const productPickupDatesSelector = createSelector([selectPortalOrder], ({ productPickupDates }) => {
  const defaultPickupDate = findFirstCommonDate(productPickupDates);
  return {
    productPickupDates,
    defaultPickupDate,
  };
});

export const {
  setAccountNumber,
  setAccountsWithProducts,
  setEnableResidentialFromAddress,
  setEnableResidentialToAddress,
  setResidentialAddressFromRequired,
  setResidentialAddressToRequired,
  setProductPickupDates,
  setPickupDates,
  setSelectedPickupDate,
  resetPortalOrderSlice,
} = portalOrderSlice.actions;

export const portalOrderReducer = portalOrderSlice.reducer;
