import { getCurrentDateWithTime } from 'globals/utils/formatting';
import {
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  SENDER_PAYER,
} from '../../../globals/constants';
import { config } from '../../data-config';
import { getAdditionalServicesForOneSelectedProduct } from '../Template/DeliveryOptions/ServiceDetails';
import { payerCode, readState, setAccountInfo } from '../Template/Route/Route-helpers';
import { preparePhoneNumber } from 'hooks/usePhoneNumber';

const setParty = (newState, templateData, name, type, customerType) => {
  const party = templateData.savedShipmentParties?.find(partyF => partyF.type === customerType);
  const stateToSet = JSON.parse(JSON.stringify(newState));

  stateToSet[`${type}Id`] = party?.id;
  stateToSet[`${type}${name}`].value = party?.companyName || '';
  stateToSet[`${type}Email`].value = party?.email || '';
  stateToSet[`${type}EmailConfirmation`].value = party?.emailConfirmation || '';
  stateToSet[`${type}Country`].value = party?.countryCode;
  stateToSet[`${type}Name`].value = party?.name || '';
  stateToSet[`${type}Street`].value = party?.street || '';
  stateToSet[`${type}PostalCode`].value = party?.postalCode || '';
  stateToSet[`${type}City`].value = party?.city || '';
  const { code, number } = preparePhoneNumber(party?.phone);
  stateToSet[`${type}PhoneNumbers`] = [
    {
      code,
      error: false,
      isRequired: true,
      number,
    },
  ];

  return stateToSet;
};

const setShipmentData = (templateDataShipmentDetails, templateTotalLdm) => {
  const stateObj = {};
  stateObj.shipmentDetailsRows = [];

  let quantity = 0;
  let volume = 0;
  let loadingMeter = templateTotalLdm || '';
  let weight = 0;

  templateDataShipmentDetails?.forEach(templateShipmentRow => {
    const shipmentRow = JSON.parse(JSON.stringify(config.shipmentTypesFields[templateShipmentRow.type]));
    shipmentRow.id = templateShipmentRow.id;
    shipmentRow.height.value = templateShipmentRow.height || '';
    shipmentRow.length.value = templateShipmentRow.length || '';
    shipmentRow.loadingMeter.value = templateShipmentRow.loadMeter || '';
    shipmentRow.quantity.value = templateShipmentRow.quantity || '';
    shipmentRow.shipmentType.value = templateShipmentRow.type;
    shipmentRow.nonStackable.value = templateShipmentRow.nonStackable;
    shipmentRow.volume.value = templateShipmentRow.volume || '';
    shipmentRow.weight.value = templateShipmentRow.weight || '';
    shipmentRow.width.value = templateShipmentRow.width || '';

    // international specific - dangerous goods & goods desc & shipment mark
    shipmentRow.goodsDescription.value = templateShipmentRow.goodsDescription || '';
    shipmentRow.shippingMark.value = templateShipmentRow.shipmentMark || '';
    shipmentRow.dangerousGoodGroup = JSON.parse(JSON.stringify(config.dangerousGoodGroup));

    // set disable
    shipmentRow.nonStackable.disabled = false;

    if (templateShipmentRow.dangerousGoods && shipmentRow.dangerousGoods) {
      if (templateShipmentRow.dangerousGoods.length) {
        shipmentRow.dangerousGoods.value = true;
      }

      shipmentRow.dangerousGoodGroup = templateShipmentRow.dangerousGoods.map(item => {
        return setDangerousGoods(item);
      });
    }

    quantity += shipmentRow.quantity.value;
    volume += shipmentRow.volume.value * shipmentRow.quantity.value;
    weight += shipmentRow.weight.value * shipmentRow.quantity.value;

    stateObj.shipmentDetailsRows.push(shipmentRow);
  });

  stateObj.totals = {
    shipmentDetailsRows: {
      quantity: { value: quantity },
      volume: { value: volume ? volume.toFixed(config.shipmentInputs.volume.maxDecimals) : volume },
      weight: { value: weight ? weight.toFixed(config.shipmentInputs.weight.maxDecimals) : weight },
      loadingMeter: {
        value: loadingMeter ? loadingMeter.toFixed(config.shipmentInputs.loadingMeter.maxDecimals) : loadingMeter,
      },
      chargeableWeight: { value: null, error: false },
    },
  };

  return [stateObj.shipmentDetailsRows, stateObj.totals];
};

const setDangerousGoods = data => {
  let dangerousGoodGroup = {
    adrClass: {},
    unNumber: {},
    flamePoint: {},
    packagingGroup: {},
    tunnelDescriptionCode: {},
    properShippingName: {},
    technicalDescription: {},
    limitedQuantity: {},
    grossWeight: {},
    netQuantity: {},
    numberOfUnits: {},
    packageCode: {},
    quantityMeasurementUnitQualifier: {},
    environmentHazardous: {},
    marinePollutant: {},
    marinePollutantName: {},
    exceptedQuantity: {},
    emptyContainer: {},
    waste: {},
    dgmId: {},
  };

  dangerousGoodGroup.adrClass.value = data.adrClass || '';
  dangerousGoodGroup.unNumber.value = data.unNumber || '';
  dangerousGoodGroup.flamePoint.value = data.flamePoint || '';
  dangerousGoodGroup.packagingGroup.value = data.packagingGroup || '';
  dangerousGoodGroup.tunnelDescriptionCode.value = data.tunnelDescriptionCode || '';
  dangerousGoodGroup.properShippingName.value = data.properShippingName || '';
  dangerousGoodGroup.technicalDescription.value = data.technicalDescription || '';
  dangerousGoodGroup.limitedQuantity.value = data.limitedQuantity || false;
  dangerousGoodGroup.grossWeight.value = data.grossWeight || '';
  dangerousGoodGroup.netQuantity.value = data.netQuantity || '';
  dangerousGoodGroup.numberOfUnits.value = data.numberOfUnits || '';
  dangerousGoodGroup.packageCode.value = data.packageCode || '';
  dangerousGoodGroup.quantityMeasurementUnitQualifier.value = data.quantityMeasurementUnitQualifier || '';
  dangerousGoodGroup.environmentHazardous.value = data.environmentHazardous || false;
  dangerousGoodGroup.marinePollutant.value = data.marinePollutant || false;
  dangerousGoodGroup.marinePollutantName.value = data.marinePollutantName || '';
  dangerousGoodGroup.exceptedQuantity.value = data.exceptedQuantity || false;
  dangerousGoodGroup.emptyContainer.value = data.emptyContainer || false;
  dangerousGoodGroup.waste.value = data.waste || false;
  dangerousGoodGroup.dgmId.value = data.dgmId || '';
  dangerousGoodGroup.id = data.id;

  return dangerousGoodGroup;
};

const addOptions = (selectedVasWithOptionsSchema, options, groupIndex) => {
  const newOptions = JSON.parse(JSON.stringify(options));

  const maxOptionSchemaValue = selectedVasWithOptionsSchema.reduce(
    (vas1, vas2) => {
      return vas2.groupIndex === groupIndex && vas2.optionIndex >= vas1.optionIndex ? vas2 : vas1;
    },
    {
      optionIndex: 0,
    },
  );
  const maxRepeat = maxOptionSchemaValue.optionIndex;

  if (maxRepeat > 0) {
    const firstOption = options[0];
    let repeat = 0;

    do {
      newOptions.push(firstOption);
      repeat++;
    } while (repeat < maxRepeat);
  }

  return newOptions;
};

const setAdditionalServices = async (stateToSet, templateDataVas = false, countryConfig = {}) => {
  // make vas api and add it in state

  Object.assign(
    stateToSet,
    await getAdditionalServicesForOneSelectedProduct(stateToSet.product.value, stateToSet, countryConfig),
  );

  if (templateDataVas && templateDataVas.length > 0) {
    // iterate additionalServices
    stateToSet.additionalServices.map(additionalService => {
      // each selected vas in template date
      const selectedVasWithOptionsSchema = templateDataVas.filter(
        templateVas => templateVas.vas === additionalService.code,
      );

      // check if stateObj vas is true
      if (selectedVasWithOptionsSchema.length > 0) {
        additionalService.value = true;

        // check if options schema is present for stateobj vas
        if (additionalService.groups && additionalService.groups.length > 0) {
          // vasField means options schema

          additionalService.groups.forEach((group, groupIndex) => {
            if (group && group.options) {
              group.options = addOptions(selectedVasWithOptionsSchema, group.options, groupIndex);

              group.options.forEach((optionList, optionIndex) => {
                optionList.forEach(option => {
                  // selected vas field
                  const selectedVas = selectedVasWithOptionsSchema.find(
                    vas =>
                      option.optionCode === vas.vasField &&
                      groupIndex === vas.groupIndex &&
                      optionIndex === vas.optionIndex,
                  );
                  // find if options schema is present
                  if (selectedVas) {
                    // set options schema value
                    option.value =
                      config.OptionFieldTypes.Telephone === option.type
                        ? [
                            {
                              code: selectedVas.vasFieldValue.split('-')[0],
                              error: false,
                              isRequired: true,
                              number: selectedVas.vasFieldValue.split('-')[1],
                            },
                          ]
                        : selectedVas.vasFieldValue;

                    option.id = selectedVas.id;
                  }
                });
              });
            }
          });
        } else {
          additionalService.id = selectedVasWithOptionsSchema[0].id;
        }
      }

      return additionalService;
    });

    stateToSet.additionalServices = disableNotCompatibleVAS(stateToSet.additionalServices);
  }

  return stateToSet;
};

const disableNotCompatibleVAS = oldAdditionalServices => {
  const additionalServices = JSON.parse(JSON.stringify(oldAdditionalServices));
  const deSelectVasList = [];
  // iterate each vas
  additionalServices.forEach(additionalService => {
    if (additionalService.value && additionalService.notCompatibleWith) {
      deSelectVasList.push(...additionalService.notCompatibleWith);
    }
  });

  // remove duplicates
  const newDeselectList = deSelectVasList.filter((item, index) => deSelectVasList.indexOf(item) === index);
  additionalServices.forEach(additionalService => {
    if (newDeselectList.indexOf(additionalService.code) !== -1) {
      additionalService.value = false;
      additionalService.disabled = true;
      additionalService.selectedStatus = false;
    }
  });

  return additionalServices;
};

const formTemplateData = async (ctxState, templateData, t, countryConfig) => {
  // assign existing state and reset required fields
  const stateToSet = Object.assign({}, ctxState, resetTemplate(t));

  stateToSet.id = templateData.id;
  stateToSet.templateName.value = templateData.templateName;

  //Route
  stateToSet.pickupFromDifferentAddress.value = templateData.savedShipmentParties?.some(
    party => party.type === 'Pickup',
  );
  stateToSet.deliverToDifferentAddress.value = templateData.savedShipmentParties?.some(
    party => party.type === 'Delivery',
  );

  // diff pickup address and residential case
  if (templateData.sdifferent) {
    // diff pickup address is true <-> pickup residential is false
    stateToSet.pickupFromDifferentAddress.value = true;
    stateToSet.pickupAddressResidential.value = false;
    // since diff pickup address is true; residential value is set to diff pickup residential
    stateToSet.differentPickupAddressResidential.value = templateData.sresidential;
  } else {
    // diff pickup  address is false, hence diff pickup residential also false
    stateToSet.pickupFromDifferentAddress.value = false;
    stateToSet.differentPickupAddressResidential.value = false;
    // residential add set to pickup residential
    stateToSet.pickupAddressResidential.value = templateData.sresidential;
  }

  // diff delivery address and residential case
  if (templateData.rdifferent) {
    // diff delivery address is true <-> delivery residential is false
    stateToSet.deliverToDifferentAddress.value = true;
    stateToSet.deliveryAddressResidential.value = false;
    // since diff delivery address is true; residential value is set to diff delivery residential
    stateToSet.differentDeliveryAddressResidential.value = templateData.rresidential;
  } else {
    // diff delivery  address is false, hence diff delivery residential also false
    stateToSet.deliverToDifferentAddress.value = false;
    stateToSet.differentDeliveryAddressResidential.value = false;
    // residential add set to delivery residential
    stateToSet.deliveryAddressResidential.value = templateData.rresidential;
  }

  Object.assign(stateToSet, setParty(stateToSet, templateData, SENDER_PAYER, 'pickup', 'sender'));
  Object.assign(stateToSet, setParty(stateToSet, templateData, RECEIVER_PAYER, 'delivery', 'receiver'));

  if (stateToSet.pickupFromDifferentAddress.value) {
    Object.assign(stateToSet, setParty(stateToSet, templateData, SENDER_PAYER, 'differentPickup', 'differentSender'));
  } else {
    stateToSet.differentPickupCountry.value = stateToSet.pickupCountry.value;
  }

  if (stateToSet.deliverToDifferentAddress.value) {
    Object.assign(
      stateToSet,
      setParty(stateToSet, templateData, RECEIVER_PAYER, 'differentDelivery', 'differentReceiver'),
    );
  } else {
    stateToSet.differentDeliveryCountry.value = stateToSet.deliveryCountry.value;
  }

  stateToSet.pickupSenderReference.value = templateData.sreference || '';
  stateToSet.deliveryReceiverReference.value = templateData.rreference || '';

  stateToSet.termsOfDelivery.value = templateData.termsOfDelivery;

  stateToSet.product.value = templateData.productID || '';

  // set shipment details
  [stateToSet.shipmentDetailsRows, stateToSet.totals] = setShipmentData(
    templateData.savedShipmentPieces,
    templateData?.totalLDM,
  );
  stateToSet.dimensions = JSON.parse(JSON.stringify(config.dimensions));

  //Schedule Pickup
  stateToSet.pickupOption.value = templateData.schedulePickup
    ? 'schedulePickup'
    : templateData.dropAtDHL
    ? 'dropOffPackage'
    : templateData.useExistingPickup
    ? 'useAlreadyScheduled'
    : '';

  // instructions
  stateToSet.deliveryInstructions.value = templateData.deliveryInstruction || '';
  stateToSet.pickupInstructions.value = templateData.pickupInstruction || '';

  // account - import section info
  stateToSet.shipmentRange.value = templateData.route;
  stateToSet.shipmentTypesImpOrExp.value = templateData.importExport;
  stateToSet.shipmentPayer.value = templateData.whoPay;

  // Fix for International Import
  // Note: internaitonal import doesn't allow sender to pay
  if (
    stateToSet.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
    stateToSet.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE &&
    stateToSet.shipmentPayer.value === SENDER_PAYER
  ) {
    stateToSet.shipmentPayer.value = RECEIVER_PAYER;
  }

  Object.assign(stateToSet, setImportSectionData(stateToSet, templateData));

  if (stateToSet.product.value) {
    // set vas
    Object.assign(stateToSet, await setAdditionalServices(stateToSet, templateData.savedShipmentVass, countryConfig));
  }

  return stateToSet;
};

export const setTemplate = async (context, t, countryConfig) => {
  if (context.state.templateData && !context.state.editTemplateError) {
    const stateToSet = await formTemplateData(context.state, context.state.templateData, t, countryConfig);
    context.updateState(stateToSet);
  }
};

const setImportSectionData = (previousCtxState, templateData) => {
  const stateToSet = JSON.parse(JSON.stringify(previousCtxState));

  const opts = readState(stateToSet);
  const casePayerCode = payerCode(opts);

  const senderAccount = stateToSet.accounts.some(account => account.code === templateData.senderAccount)
    ? templateData.senderAccount
    : '';

  if (casePayerCode !== 'case9') {
    Object.assign(previousCtxState, stateToSet);
    setAccountInfo(casePayerCode, previousCtxState, stateToSet);
  }

  if (stateToSet.accountNumber.display && stateToSet.receiverNumber.display) {
    // sender account from account list
    if (senderAccount) {
      stateToSet.accountNumber.value = senderAccount;
      stateToSet.receiverNumber.value = templateData.otherAccount;
    } else {
      // receiver account from account list
      stateToSet.accountNumber.value = templateData.senderAccount;
      stateToSet.receiverNumber.value = templateData.otherAccount;
    }

    stateToSet.accountNumber.isSelect = templateData.isSenderAccSelect || false;
    stateToSet.receiverNumber.isSelect = templateData.isOtherAccSelect || false;
  } else if (stateToSet.accountNumber.display) {
    stateToSet.accountNumber.value = senderAccount;
    stateToSet.accountNumber.isSelect = templateData.isSenderAccSelect || false;
  } else if (stateToSet.receiverNumber.display) {
    stateToSet.receiverNumber.value = senderAccount;
    stateToSet.receiverNumber.isSelect = templateData.isOtherAccSelect || false;
  }

  if (stateToSet.thirdPartyNumber.display) {
    stateToSet.thirdPartyNumber.value = templateData.otherAccount;
    stateToSet.thirdPartyNumber.isSelect = templateData.isOtherAccSelect || false;
  }

  if (stateToSet.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE) {
    const customsCountry = stateToSet.internationalShippingCountries.find(toCountry => {
      return toCountry.countryCode === stateToSet.deliveryCountry.value;
    });

    stateToSet['countryBasedCustoms'] = customsCountry && customsCountry.customs;
  }

  stateToSet.accountNumber.value = stateToSet.accountNumber.value || '';
  stateToSet.receiverNumber.value = stateToSet.receiverNumber.value || '';
  stateToSet.thirdPartyNumber.value = stateToSet.thirdPartyNumber.value || '';

  return stateToSet;
};

export const resetTemplate = t => {
  return {
    templateData: [],
    id: '',

    //postal code validation
    postalCodeState: {
      apiError: false,
      submitError: false,
      sourcePostalCodeError: false,
      destinationPostalCodeError: false,
      sourceCityError: false,
      destinationCityError: false,
    },

    templateName: {
      value: t(`portalTemplates|New Template`) + ' ' + getCurrentDateWithTime(),
      error: false,
      isRequired: true,
    },
    //Import Export Section
    accountNumber: { value: '', error: false, isRequired: true, display: true, isSelect: true },
    receiverNumber: { value: '', error: false, isSelect: false },
    thirdPartyNumber: { value: '', error: false },
    customs: false,
    countryBasedCustoms: false,
    shipmentRange: { value: DOMESTIC_SHIPMENT_RANGE },
    shipmentTypesImpOrExp: { value: EXPORT_SHIPMENT_TYPE },
    prevShipmentTypesImpOrExp: { value: EXPORT_SHIPMENT_TYPE },
    shipmentPayer: { value: SENDER_PAYER },

    //Route
    pickupFromDifferentAddress: { value: false },
    deliverToDifferentAddress: { value: false },

    pickupAddressResidential: { value: false },
    differentPickupAddressResidential: { value: false },

    deliveryAddressResidential: { value: false },
    differentDeliveryAddressResidential: { value: false },

    pickupId: '',
    pickupSender: { value: '', error: false, isRequired: true },
    pickupEmail: { value: '', error: false, isRequired: false },
    pickupEmailConfirmation: { value: false, error: false },
    pickupCountry: { value: null, error: false, comparisonError: false, isRequired: true },
    pickupVatNumber: { value: '', error: false },
    pickupName: { value: '', error: false, isRequired: true },
    pickupStreet: { value: '', error: false, isRequired: true },
    pickupPostalCode: { value: '', error: false, isRequired: true },
    pickupCity: { value: '', error: false, isRequired: true },
    pickupPhoneNumbers: [{ code: '', number: '', error: '', isRequired: true }],
    pickupSenderReference: { value: '', error: false },

    differentPickup: '',
    differentPickupSender: { value: '', error: false, isRequired: true },
    differentPickupEmail: { value: '', error: false, isRequired: false },
    differentPickupEmailConfirmation: { value: false, error: false },
    differentPickupCountry: { value: null, error: false, comparisonError: false, isRequired: true },
    differentPickupVatNumber: { value: '', error: false },
    differentPickupName: { value: '', error: false, isRequired: true },
    differentPickupStreet: { value: '', error: false, isRequired: true },
    differentPickupPostalCode: { value: '', error: false, isRequired: true },
    differentPickupCity: { value: '', error: false, isRequired: true },
    differentPickupPhoneNumbers: [{ code: '', number: '', error: '', isRequired: true }],

    deliveryId: '',
    deliveryReceiver: { value: '', error: false, isRequired: true },
    deliveryEmail: { value: '', error: false, isRequired: false },
    deliveryEmailConfirmation: { value: false, error: false },
    deliveryCountry: { value: null, error: false, comparisonError: false, isRequired: true },
    deliveryCompany: { value: '', error: false },
    deliveryVatNumber: { value: '', error: false },
    deliveryName: { value: '', error: false, isRequired: true },
    deliveryStreet: { value: '', error: false, isRequired: true },
    deliveryPostalCode: { value: '', error: false, isRequired: true },
    deliveryCity: { value: '', error: false, isRequired: true },
    deliveryPhoneNumbers: [{ code: '', number: '', error: '', isRequired: true }],
    deliveryReceiverReference: { value: '', error: false },

    differentDeliveryId: '',
    differentDeliveryReceiver: { value: '', error: false, isRequired: true },
    differentDeliveryEmail: { value: '', error: false, isRequired: false },
    differentDeliveryEmailConfirmation: { value: false, error: false },
    differentDeliveryCountry: { value: null, error: false, comparisonError: false, isRequired: true },
    differentDeliveryCompany: { value: '', error: false },
    differentDeliveryVatNumber: { value: '', error: false },
    differentDeliveryName: { value: '', error: false, isRequired: true },
    differentDeliveryStreet: { value: '', error: false, isRequired: true },
    differentDeliveryPostalCode: { value: '', error: false, isRequired: true },
    differentDeliveryCity: { value: '', error: false, isRequired: true },
    differentDeliveryPhoneNumbers: [{ code: '', number: '', error: '', isRequired: true }],

    // Address book
    addToAddressBookSender: { value: false, error: false },
    addToAddressBookReceiver: { value: false, error: false },
    updateInAddressBookSender: { value: false, error: false },
    updateInAddressBookReceiver: { value: false, error: false },

    senderPickedFromAddressBook: false,
    receiverPickedFromAddressBook: false,
    senderAddressId: { value: '', error: false },
    receiverAddressId: { value: '', error: false },
    showCompanySearch: false,
    addressPickupSearch: { value: '', error: false },

    termsOfDelivery: { value: '', error: false, isRequired: true, possibleValues: ['val1', 'val2'] },

    // Delivery Options
    product: { value: '', error: false },
    additionalServices: [],
    additionalServicesError: false,
    additionalServicesAPIError: false,
    geoLocationError: false,

    // Shipment details
    payer: { value: SENDER_PAYER, error: false },
    shipmentRowsNumberMax: config.maxAllowedShipmentDetailsRows,
    shipmentDetailsRows: [],
    numberOfPallets: { value: 0, error: false },

    totals: {
      shipmentDetailsRows: {
        quantity: { value: 1 },
        volume: { value: 0 },
        weight: { value: 0 },
        loadingMeter: { value: 0 },
        chargeableWeight: { value: null, error: false },
      },
    },

    //Schedule Pickup
    pickupOption: { value: '', error: false, isRequired: true },
    deliveryInstructions: { value: '', error: false, isRequired: true },
    pickupInstructions: { value: '', error: false, isRequired: true },
  };
};
