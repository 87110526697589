import { faEnvelope, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Error } from 'components';
import { formatDate } from 'globals/utils/formatting';
import getBaseUrl from 'globals/utils/getUrlBase';
import { setCookie } from 'globals/utils/requests';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translation } from 'react-i18next';
import { ROUTES } from 'router';
import { reviewAndBookDatePresenter, toDateComponent } from '../../../globals/helpers/side-summary-helpers';
import { config } from '../../data-config';
import {
  createSummaryEmailAndPrintData,
  formQuoteSummaryPrice,
  getAccountNumber,
  noPriceAvailable,
  printEmailQuote,
  saveDataAsTemplate2AndCallPortalOrder,
} from './ReviewQuote-helpers';
import './ReviewQuote.css';
import { trackGetQuote, TrackGetQuoteStep } from '../../helpers/journeyTracking';

class ReviewQuote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      generalError: false,
      fixedDate: false,
      quoteSummary: {},
      PDFDownloadError: false,
      downloadPDF: false,
      isPrint: false,
      proceedToBookingError: false,
      disableProceedToBooking: false,
    };

    this.ONE_MONTH = 720;
    this._isMounted = false;
  }

  componentDidMount = () => {
    this._isMounted = true;
    const deliveryState = this.props.deliveryContext.state;

    this.setState({
      quoteSummary: formQuoteSummaryPrice(
        JSON.parse(JSON.stringify(deliveryState.quotePriceMap)),
        deliveryState.additionalServices,
        deliveryState.selectedProduct,
      ),
      disableProceedToBooking: !getAccountNumber(this.props.routeContext.state, deliveryState),
    });

    trackGetQuote(this.props.journeyId, TrackGetQuoteStep.REVIEW_QUOTE);

    window.scrollTo(0, 0);
  };

  startANewQuote = () => {
    if (this._isMounted) {
      window.location.reload();
    }
  };

  proceedToBooking = async t => {
    this.setState({ loader: true }, async () => {
      trackGetQuote(this.props.journeyId, TrackGetQuoteStep.PROCEED_TO_BOOKING);
      const saveShipmentResult = await saveDataAsTemplate2AndCallPortalOrder(this.props, this.state);

      if (!saveShipmentResult.error) {
        setCookie(config.portalOrderCookie, saveShipmentResult.data.id);
        window.location = `${getBaseUrl(ROUTES.portal)}/${ROUTES.portalOrder}`;
      } else {
        this.setState({
          proceedToBookingError: true,
          loader: false,
        });
      }
    });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  quotePrintEmail = async (isPrint, t) => {
    const stateObj = {};
    stateObj.downloadPDF = true;
    stateObj.isPrint = isPrint;

    this.setState({
      downloadPDF: false,
    });

    const body = createSummaryEmailAndPrintData(
      this.state.quoteSummary,
      this.props.routeContext.state,
      this.props.shipmentContext.state,
      this.props.deliveryContext.state,
      t,
    );

    await printEmailQuote(isPrint, body).then(result => {
      if (result.error) {
        stateObj.PDFDownloadError = isPrint ? true : false;
        stateObj.EmailError = !isPrint ? true : false;
      } else {
        stateObj.PDFDownloadError = false;
        stateObj.EmailError = false;
      }

      this.setState(stateObj);
    });

    setTimeout(() => {
      this.setState({
        downloadPDF: false,
      });
    }, 6000);
  };

  checkValidPrice = price => {
    if (!price || !price.value || price.value === 'null' || price.value === 0) {
      return '---';
    }
    return price.value + ' ' + price.unit;
  };

  getChargeableWeight = (quoteSummary, selectedProduct, t) => {
    const chargedWeightFromQuote =
      quoteSummary.ChargedWeight && selectedProduct.code !== config.dhlPALL
        ? quoteSummary.ChargedWeight.calculatedResult
        : null;

    const volWeightFromQuote =
      quoteSummary.VolWeight && selectedProduct.code !== config.dhlPALL ? quoteSummary.VolWeight.value : null;

    if (chargedWeightFromQuote) return chargedWeightFromQuote;
    if (volWeightFromQuote) return volWeightFromQuote + ' ' + t('general|kg');
    return '---';
  };

  render() {
    const selectedProduct = this.props.deliveryContext.state.matchedProducts.find(product => product.value);
    const { quoteSummary } = this.state;

    return (
      <div className="frc__generic-section--wrapper">
        <Translation>
          {t => (
            <>
              <section className="frc__review-summary">
                <h4 data-testid="productName" className="frc__review-summary__title">
                  {t(`products|${selectedProduct.code}|name`)}
                </h4>

                <div
                  className="l-grid l-grid--w-100pc-s l-grid--w-50pc-s-m l-grid--w-50pc-s-l l-grid--w-20pc-m 
                l-grid--w-50pc-m-s l-grid--left-m l-grid--center-s frc__summary-header-block"
                >
                  <div className="l-grid l-grid--w-100pc-s frc__summary-header-block-box">
                    <div className="l-grid l-grid--w-100pc-s ">{t('general|Pickup Date')}:</div>
                    <div className="l-grid l-grid--w-100pc-s ">
                      <strong> {formatDate(this.props.selectedPickupDate, t('general|dateFormat'))}</strong>
                    </div>
                  </div>
                </div>

                <div
                  className="l-grid l-grid--w-100pc-s l-grid--w-50pc-s-m l-grid--w-50pc-s-l l-grid--w-20pc-m 
                l-grid--w-50pc-m-s l-grid--left-m l-grid--center-s frc__summary-header-block"
                >
                  <div className="l-grid l-grid--w-100pc-s frc__summary-header-block-box">
                    {toDateComponent(this.props.deliveryContext.state, reviewAndBookDatePresenter)}
                  </div>
                </div>

                <div
                  className="l-grid l-grid--w-100pc-s l-grid--w-50pc-s-m l-grid--w-50pc-s-l l-grid--w-20pc-m 
                l-grid--w-50pc-m-s l-grid--left-m l-grid--center-s frc__summary-header-block"
                >
                  <div className="l-grid l-grid--w-100pc-s frc__summary-header-block-box">
                    <div className="l-grid l-grid--w-100pc-s ">{t(`general|Distance`)}:</div>
                    <div className="l-grid l-grid--w-100pc-s  ">
                      <strong> {quoteSummary.Distance ? quoteSummary.Distance.calculatedResult : '---'}</strong>
                    </div>
                  </div>
                </div>

                <div
                  className="l-grid l-grid--w-100pc-s l-grid--w-50pc-s-m l-grid--w-50pc-s-l l-grid--w-20pc-m 
                l-grid--w-50pc-m-s l-grid--left-m l-grid--center-s frc__summary-header-block"
                >
                  <div className="l-grid l-grid--w-100pc-s frc__summary-header-block-box">
                    <div className="l-grid l-grid--w-100pc-s">{t(`general|Chargeable weight`)}:</div>
                    <div className="l-grid l-grid--w-100pc-s ">
                      <strong>{this.getChargeableWeight(quoteSummary, selectedProduct, t)}</strong>
                    </div>
                  </div>
                </div>

                <div className="l-grid--w-100pc-s l-grid--w-20pc-m l-grid--w-100pc-m-s frc__summary-header-block">
                  <div className="l-grid l-grid--center-s">
                    <div className="l-grid-column-m-m frc__summary-header-buttons">
                      <button
                        className={'base-button base-button--white frc__base-button'}
                        id="printBtn"
                        data-testid="printBtn"
                        onClick={() => this.quotePrintEmail(true, t)}
                      >
                        <FontAwesomeIcon icon={faPrint} className="frc__icon" />
                        <span className="frc__icon-spacing">{t(`quoteTool|Print`)}</span>
                      </button>
                      <button
                        className={'base-button base-button--white frc__base-button'}
                        id="emailBtn"
                        data-testid="emailBtn"
                        onClick={() => this.quotePrintEmail(false, t)}
                      >
                        <FontAwesomeIcon icon={faEnvelope} className="frc__icon" />
                        <span className="frc__icon-spacing">{t(`general|E-mail`)}</span>
                      </button>
                    </div>
                    {this.state.downloadPDF && (
                      <p
                        className={`frc__summary-header-feedback-text-msg 
                     ${
                       this.state.PDFDownloadError || this.state.EmailError
                         ? 'frc__summary-header-feedback-text-msg--error'
                         : 'frc__summary-header-feedback-text-msg--success'
                     }`}
                      >
                        {this.state.downloadPDF && this.state.isPrint
                          ? this.state.PDFDownloadError
                            ? t('general|Some error occured. Try later.')
                            : t(`quoteTool|PDF document has been successfully downloaded.`)
                          : ''}

                        {this.state.downloadPDF && !this.state.isPrint
                          ? this.state.EmailError
                            ? t('general|Some error occured. Try later.')
                            : t(`quoteTool|Email has been successfully sent.`)
                          : ''}
                      </p>
                    )}
                  </div>
                </div>
              </section>

              <div className=" l-grid--w-100pc-s frc__generic--field-wrapper ">
                <h4 className="margin-bottom-0 frc__red-lined-title">{t('general|Price Breakdown')}</h4>
                <div className="frc__shipment-details-row">
                  <div className="frc__summary-row-section ">
                    <div className="l-grid frc__summary-line">
                      <div className="l-grid--w-75pc-s">
                        <h5 data-testid="price" className="frc__price-breakdown-title">
                          {' '}
                          {t('general|Net price per agreement')}
                        </h5>
                      </div>
                      <div className="l-grid--w-25pc-s l-grid--right-s ">
                        <h5 data-testid="price" className="frc__price-breakdown-title">
                          {noPriceAvailable({ quoteSummary }) ? (
                            <>{t('general|errors|No price available online')}</>
                          ) : (
                            <>{quoteSummary.FreightCost ? this.checkValidPrice(quoteSummary.FreightCost) : '---'}</>
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>
                  {quoteSummary.additionalServices && quoteSummary.additionalServices.length > 0 && (
                    <div className="frc__summary-row-section">
                      <div className="js-fcp-summary-additional-services">
                        <div className="frc__summary-row-section-title">
                          <h5 data-testid="price" className="frc__price-breakdown-title">
                            {' '}
                            {t('general|Additional Services')}:
                          </h5>
                        </div>
                        {quoteSummary.additionalServices.map((AD, index) => {
                          return (
                            <div key={index} className="l-grid frc__summary-line">
                              <div className="l-grid--w-75pc-s js--fcp-summary--single-AS-name">
                                {t(`additionalServices|${AD.code}|name`)}
                              </div>
                              <div className="l-grid--w-25pc-s js--fcp-summary--single-AS-price l-grid--right-s">
                                {noPriceAvailable({ quoteSummary }) ? (
                                  <>{t('general|errors|No price available online')}</>
                                ) : (
                                  <>{AD.price}</>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <div className="frc__summary-row-section ">
                    <div className="frc__summary-row-section-title">
                      <h5 data-testid="price" className="frc__price-breakdown-title">
                        {' '}
                        {t('general|Surcharges (price per surcharge)')}
                      </h5>
                    </div>
                    <div className="js--fcp-summary-surcharges-section">
                      {quoteSummary.surcharges &&
                        quoteSummary.surcharges.map((surcharge, index) => {
                          let price = surcharge.calculatedResult ? this.checkValidPrice(surcharge) : '---';
                          if (noPriceAvailable({ quoteSummary })) {
                            price = t('general|errors|No price available online');
                          }

                          return (
                            <div key={index} className="l-grid frc__summary-line">
                              <div className="l-grid--w-75pc-s ">{surcharge.label}</div>
                              <div className="l-grid--w-25pc-s l-grid--right-s ">{price}</div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <div className="frc__summary-row-section ">
                    <div className="l-grid frc__summary-line frc__summary-total-price">
                      <div className="l-grid--w-75pc-s">
                        <h4 data-testid="price" className="frc__price-breakdown-title">
                          {t('general|labels|metrics|Total price')} {t(`general|(excl. VAT)`)}*
                        </h4>
                      </div>
                      <div className="l-grid--w-25pc-s l-grid--right-s">
                        <h4 data-testid="price" className="frc__price-breakdown-title">
                          {' '}
                          {noPriceAvailable({ quoteSummary }) ? (
                            <>{t('general|errors|No price available online')}</>
                          ) : (
                            <>{quoteSummary.TotalPriceNoVATNOMarkup ? quoteSummary.TotalPriceNoVATNOMarkup : '---'} </>
                          )}
                        </h4>
                      </div>
                    </div>
                    <div className="l-grid frc__summary-line">
                      <div className="l-grid--w-75pc-s">{t(`quoteTool|Markup`)}</div>
                      <div className="l-grid--w-25pc-s l-grid--right-s ">
                        {' '}
                        {noPriceAvailable({ quoteSummary }) ? (
                          <>{t('general|errors|No price available online')}</>
                        ) : (
                          <>
                            {quoteSummary.OwnSurChargeAmount
                              ? this.checkValidPrice(quoteSummary.OwnSurChargeAmount)
                              : '---'}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="l-grid frc__summary-line">
                      <div className="l-grid--w-75pc-s">{t(`quoteTool|Markup percentage`)}</div>
                      <div className="l-grid--w-25pc-s l-grid--right-s ">
                        {' '}
                        {noPriceAvailable({ quoteSummary }) ? (
                          <>{t('general|errors|No price available online')}</>
                        ) : (
                          <>
                            {quoteSummary.OwnSurChargePerc
                              ? this.checkValidPrice(quoteSummary.OwnSurChargePerc)
                              : '---'}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="l-grid frc__summary-line">
                      <div className="l-grid--w-75pc-s">
                        {t(`quoteTool|Total price including markup`)}
                        <span>&nbsp;</span>
                        <small> {t(`general|(excl. VAT)`)}</small>*
                      </div>
                      <div className="l-grid--w-25pc-s l-grid--right-s ">
                        {noPriceAvailable({ quoteSummary }) ? (
                          <>{t('general|errors|No price available online')}</>
                        ) : (
                          <>{quoteSummary.TotalPrice ? quoteSummary.TotalPrice : '---'}</>
                        )}
                      </div>
                    </div>
                    <div className="l-grid frc__summary-line">
                      <div className="l-grid--w-75pc-s">{t('general|VAT')}</div>
                      <div className="l-grid--w-25pc-s l-grid--right-s ">
                        {noPriceAvailable({ quoteSummary }) ? (
                          <>{t('general|errors|No price available online')}</>
                        ) : (
                          <>{quoteSummary.VAT ? this.checkValidPrice(quoteSummary.VAT) : '---'}</>
                        )}
                      </div>
                    </div>
                    <div className="l-grid frc__summary-line">
                      <div className="l-grid--w-75pc-s">{t(`quoteTool|Total price incl. VAT & Markup`)}</div>
                      <div className="l-grid--w-25pc-s l-grid--right-s">
                        <span className="l-grid--w-25pc-s l-grid--right-s ">
                          {noPriceAvailable({ quoteSummary }) ? (
                            <>{t('general|errors|No price available online')}</>
                          ) : (
                            <>
                              {quoteSummary.TotalPriceIncVAT
                                ? this.checkValidPrice(quoteSummary.TotalPriceIncVAT)
                                : '---'}
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="l-grid  frc__generic--field-wrapper frc__margin-top">
                <div className="l-grid--w-40pc-m l-grid--w-100pc-s ">
                  <button
                    className={`base-button base-button--white l-grid--w-25pc-w  ${
                      this.state.loader ? 'disabled' : ''
                    } `}
                    id="backToDeliveryOptionsBtn"
                    onClick={this.state.loader ? null : () => this.props.previousTab(config.tabs.tab3, 3)}
                  >
                    <span>{t(`general|Back to Delivery Options`)}</span>
                  </button>
                </div>

                <div className="l-grid--w-60pc-m l-grid--w-100pc-s frc__btn-display-end">
                  <button
                    className={
                      'base-button base-button--wide l-grid--w-25pc-w ' + (this.state.loader ? ' disabled' : '')
                    }
                    id="startNewQuoteBtn"
                    data-testid="startNewQuoteBtn"
                    onClick={this.state.loader ? null : () => this.startANewQuote()}
                  >
                    <span>{t(`quoteTool|Start a new quote`)}</span>
                  </button>
                  <button
                    className={
                      'base-button base-button--wide l-grid--w-25pc-w ' +
                      (this.state.loader ? ' is-loading--right' : '') +
                      (this.state.disableProceedToBooking ? ' disabled ' : '')
                    }
                    disabled={this.state.disableProceedToBooking}
                    id="proceedTobookingBtn"
                    data-testid="proceedTobookingBtn"
                    onClick={
                      this.state.disableProceedToBooking || this.state.loader ? null : () => this.proceedToBooking(t)
                    }
                  >
                    <span>{t(`quoteTool|Proceed to Booking`)}</span>
                  </button>
                </div>
              </div>

              <div className="frc__proceed-to-order-error">
                {this.state.disableProceedToBooking ? (
                  <Error name="disableProceedToBookingError" message={t(`quoteTool|Proceed to Booking Error`)} />
                ) : (
                  ''
                )}
              </div>
              <div className="l-grid--right-s margin-bottom-1">
                {this.state.proceedToBookingError ? (
                  <Error
                    name="proceedToBookingError"
                    message={
                      t('general|errors|General error') +
                      '. <br> ' +
                      t('general|Try again or call technical support.') +
                      ' ' +
                      t('general|or') +
                      ' ' +
                      `<strong><a href="${t('pageLinks|chat')}" 
                                        class ="frc__chat-with-us"
                                        target="_blank" rel="noopener noreferrer">${t(
                                          `general|Chat with us`,
                                        )}</a></strong>` +
                      ' <br> ' +
                      t('general|ID') +
                      ': ' +
                      this.props.routeContext.state.transactionId
                    }
                  />
                ) : (
                  ''
                )}
              </div>
              <div className="l-grid--right-s">
                <p className="frc__detailed-information">{t(`general|book price info`)}</p>
              </div>
            </>
          )}
        </Translation>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedPickupDate: state.quote.selectedPickupDate,
});

export default connect(mapStateToProps, null)(ReviewQuote);
