import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from '../../router';
import { isValidCountryLanguage } from '../../utils/urls';

export default function Footer() {
  const { lang } = useParams();
  const { t } = useTranslation();

  if (!isValidCountryLanguage(lang)) {
    return null;
  }

  return (
    <footer id="wcag-footer" className="c-voc-footer">
      <div className="section"></div>
      <div className="iparys_inherited">
        <div className="c-footer-meta component-wide l-grid">
          <div className="l-grid--w-100pc-s l-grid--w-70pc-m c-footer-meta-container">
            <img className="c-footer-meta-logo" src='/assets/img/footer-logo.svg' alt="DHL Group" />

            <nav className="l-grid--w-100pc-s">
              <ul className="c-footer-meta--list l-grid">
                <li className="l-grid--w-50pc-s">
                  <Link
                    to={`/${lang}/${ROUTES.legalNotice}`}
                    className="link  has-icon"
                    title="Legal Notice"
                    data-tracking='{"component":"fcp/components/general/footer-legal"}'
                    rel="noopener noreferrer"
                  >
                    {t('general|legalNotice')}
                  </Link>
                </li>
                <li className="l-grid--w-50pc-s">
                  <Link
                    to={'/' + lang + '/terms-of-use'}
                    className="link  has-icon"
                    title="Terms of Use"
                    data-tracking='{"component":"fcp/components/general/footer-legal"}'
                    rel="noopener noreferrer"
                  >
                    {t('general|Terms of Use')}
                    <span className="sr-only">Read our</span>
                  </Link>
                </li>
                <li className="l-grid--w-50pc-s">
                  <Link
                    to={'/' + lang + '/privacy-policy'}
                    className="link  has-icon"
                    title="Privacy Notice"
                    data-tracking='{"component":"fcp/components/general/footer-legal"}'
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <span className="sr-only">Read our</span>
                    {t('general|Privacy Notice')}
                  </Link>
                </li>
                <li className="l-grid--w-50pc-s">
                  <a
                    href={t('pageLinks|terms-and-conditions')}
                    target="_blank"
                    className="link link-external has-icon"
                    title="Terms of Conditions of Carriage"
                    data-tracking='{"component":"fcp/components/general/footer-legal"}'
                    rel="noopener noreferrer"
                  >
                    {t('mainMenu|Terms and Conditions of Carriage')}
                    <span className="sr-only">Read our</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="l-grid--w-100pc-s l-grid--w-30pc-m c-footer-social-container">
            <div className="c-footer-headline"> </div>
            <nav className="l-grid--w-100pc-s"> </nav>
          </div>
        </div>
        <small className="c-footer--legal-text component-wide l-grid l-grid--w-100pc-s l-grid--center-m">
          {new Date().getFullYear()} © DHL International GmbH. All rights reserved.
        </small>
      </div>
    </footer>
  );
}
