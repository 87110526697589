import { useCallback, useEffect, useState } from 'react';
import EnterPickupLocation from './EnterPickupLocation';
import { globalConfig } from '../../../globals/data-config-global';
import { getCityName } from '../../../globals/helpers/postalcode';
import { useDispatch, useSelector } from 'react-redux';
import {
  setEnableResidentialAddress,
  setEnableResidentialAddressInitially,
  setEnableResidentialToAddress,
} from '../../../store/quoteSlice';
import { afterUpdateMarketingAvailAbilityAndSelectReset } from './EnterPickupLocation-helpers';
import { useGetUserAccountsAndProductsQuery } from '../../../store/api/fcpUser';
import { useGetUser } from '../../../hooks/api/useGetUser';
import { trackGetQuote, TrackGetQuoteStep } from '../../helpers/journeyTracking';

const EMPTY_ARR = [];
export const NO_CITY_PLACEHOLDER = '';

export const QuoteRoute = props => {
  const dispatch = useDispatch();
  const { data: { productSpecifications = EMPTY_ARR } = {} } = useGetUserAccountsAndProductsQuery() || {};
  const { data: userAccountCountryCode } = useGetUser({ select: data => data?.user?.accountCountryCode });

  const [cityFrom, setCityFrom] = useState({ value: NO_CITY_PLACEHOLDER, loading: false });
  const [cityTo, setCityTo] = useState({ value: NO_CITY_PLACEHOLDER, loading: false });
  const enableCityFetch = countryCode => !globalConfig.disableCityFetchCountryList.includes(countryCode?.toUpperCase());
  const { enableResidentialAddress, enableResidentialAddressInitially, enableResidentialToAddress } = useSelector(
    state => state.quote,
  );

  const fetchCity = async ({ postalCode, countryCode }) => {
    if (!postalCode || !countryCode) {
      return NO_CITY_PLACEHOLDER;
    }
    try {
      const response = await getCityName({
        countryCode,
        postalCode,
        useCustomCountryCode: true,
        headerCountryCode: localStorage.getItem('country'),
      });
      if (response && response.data && response.data.city) {
        return response.data.city;
      } else {
        return NO_CITY_PLACEHOLDER;
      }
    } catch (error) {
      console.error('Error while fetching a city name for postal code');
      return NO_CITY_PLACEHOLDER;
    }
  };

  const loadCityFrom = useCallback(async ({ postalCode, countryCode }) => {
    if (!enableCityFetch(countryCode)) {
      return;
    }
    setCityFrom(prev => ({ ...prev, loading: true }));
    const cityFrom = await fetchCity({
      postalCode,
      countryCode,
    });
    setCityFrom(prev => ({ ...prev, value: cityFrom, loading: false }));
  }, []);

  const loadCityTo = useCallback(async ({ postalCode, countryCode }) => {
    if (!enableCityFetch(countryCode)) {
      return;
    }
    setCityTo(prev => ({ ...prev, loading: true }));
    const cityTo = await fetchCity({
      postalCode,
      countryCode,
    });
    setCityTo(prev => ({ ...prev, value: cityTo, loading: false }));
  }, []);

  useEffect(() => {
    loadCityFrom({
      postalCode: props.context.state.pickupPostalCode.value,
      countryCode: props.context.state.pickupCountry.value?.toUpperCase(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadCityFrom, props.context.state.pickupCountry.value]);

  useEffect(() => {
    loadCityTo({
      postalCode: props.context.state.deliveryPostalCode.value,
      countryCode: props.context.state.deliveryCountry.value?.toUpperCase(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadCityTo, props.context.state.deliveryCountry.value]);

  useEffect(() => {
    const productCode = props.context.state.selectedProduct?.value;
    if (productCode && productSpecifications.length) {
      const selectedProductSpecification = productSpecifications.find(
        product => product[productCode]?.code === productCode,
      );
      if (selectedProductSpecification) {
        const productSpec = selectedProductSpecification[productCode];

        if (productSpec?.marketAvailability?.some(market => market.name?.toUpperCase() === 'B2C')) {
          dispatch(setEnableResidentialAddress(true));
        } else {
          dispatch(setEnableResidentialAddress(false));
          dispatch(setEnableResidentialAddressInitially(false));
        }
      } else {
        dispatch(setEnableResidentialAddress(false));
      }
    } else if (props.context.state.productsRangeAll?.value) {
      const productsWithMarketAvailability = productSpecifications.map(product => {
        const productCode = Object.keys(product)[0];
        return {
          productCode,
          name: product[productCode].name,
          marketAvailability: product[productCode].marketAvailability,
        };
      });

      const atLeastOneProductB2C = productsWithMarketAvailability.some(product => {
        return product.marketAvailability?.some(market => market.name?.toUpperCase() === 'B2C');
      });

      if (atLeastOneProductB2C) {
        dispatch(setEnableResidentialAddress(true));
      } else {
        dispatch(setEnableResidentialAddress(false));
        dispatch(setEnableResidentialAddressInitially(false));
        if (props.context.state.deliveryAddressResidential.value) {
          props.context.updateState({
            deliveryAddressResidential: {
              value: false,
              error: false,
              comparisonError: false,
            },
          });
          afterUpdateMarketingAvailAbilityAndSelectReset({
            ...props.context,
            state: {
              ...props.context.state,
              deliveryAddressResidential: {
                value: false,
                error: false,
                comparisonError: false,
              },
            },
          });
        }
        if (props.context.state.pickupAddressResidential.value) {
          props.context.updateState({
            pickupAddressResidential: {
              value: false,
              error: false,
              comparisonError: false,
            },
          });
          afterUpdateMarketingAvailAbilityAndSelectReset({
            ...props.context,
            state: {
              ...props.context.state,
              pickupAddressResidential: {
                value: false,
                error: false,
                comparisonError: false,
              },
            },
          });
        }
      }
    } else {
      dispatch(setEnableResidentialAddress(false));
      dispatch(setEnableResidentialAddressInitially(true));
    }
  }, [
    props.context,
    props.context.state.selectedProduct?.value,
    props.context.state.productsRangeAll?.value,
    productSpecifications,
    dispatch,
  ]);

  const pickupCountry = props.context.state.pickupCountry?.value;
  const updateState = props.context.updateState;
  const deliveryAddressResidential = props.context.state.deliveryAddressResidential.value;

  useEffect(() => {
    if (
      pickupCountry?.toUpperCase() === userAccountCountryCode?.toUpperCase() ||
      userAccountCountryCode?.toUpperCase() === 'SE'
    ) {
      dispatch(setEnableResidentialToAddress(true));
    } else {
      dispatch(setEnableResidentialToAddress(false));
      if (deliveryAddressResidential) {
        updateState({
          deliveryAddressResidential: {
            value: false,
            error: false,
            comparisonError: false,
          },
        });
      }
    }
  }, [dispatch, userAccountCountryCode, pickupCountry, updateState, deliveryAddressResidential]);

  useEffect(() => {
    trackGetQuote(props.journeyId, TrackGetQuoteStep.ROUTE);
  }, [props.journeyId]);

  return (
    <EnterPickupLocation
      loadCityFrom={loadCityFrom}
      setCityFrom={setCityFrom}
      cityFrom={cityFrom}
      loadCityTo={loadCityTo}
      setCityTo={setCityTo}
      cityTo={cityTo}
      enableResidentialAddress={enableResidentialAddress}
      enableResidentialToAddress={enableResidentialToAddress}
      enableResidentialAddressInitially={enableResidentialAddressInitially}
      {...props}
    />
  );
};
