export const PROFILE_CONSTANTS = {
  accountNumberCharacterLimit: 15,
  accountReferenceNameCharacterLimit: 35,
  companyCharacterLimit: 35,
  vatNumberCharacterLimit: 30,
  personNameCharacterLimit: 35,
  streetAddressCharacterLimit: 35,
  companyPhoneNumberLimit: 16,
  emailCharacterLimit: 64,
  cityCharacterLimit: 35,
  postalCodeCharacterLimit: 9,
  irelandCountyCharacterLimit: 25,
};
