import { Button, Checkbox, Error, Input, PhoneNumber, Select } from 'components';
import { apiLinks } from 'config/api-config';
import { allCountries } from 'globals/data-countries';
import { testRegEx } from 'globals/utils/fieldValidations';
import { apiRequest } from 'globals/utils/requests';
import React from 'react';
import { POSTALCODE_ERRORS, PostalCodeInput } from '../../../../components/fcp-components/PostalCodeInput';
import {
  DOMESTIC_SHIPMENT_RANGE,
  EXPORT_SHIPMENT_TYPE,
  IMPORT_SHIPMENT_TYPE,
  INTERNATIONAL_SHIPMENT_RANGE,
  RECEIVER_PAYER,
  SENDER_PAYER,
  THIRD_PARTY_PAYER,
} from '../../../../globals/constants';
import { filterAccountsBasedOnShipmentRange } from '../../../../portal-order/components/EnterPickupLocation/ImportExportSection/ImportExportSection-helpers';
import { config, CONST } from '../../../data-config';
import { changeCountryCodePhonePrefix } from '../../../../globals/helpers/country-helpers';
import {
  resetPhoneNumberWhenSwitchedToDomestic,
  swapSenderAndReceiverAddress,
} from '../../../../globals/helpers/pickup-helpers';
import { preparePhoneNumber } from 'hooks/usePhoneNumber';

export const setPayerCodeAndProducts = (context, params) => {
  const stateObj = {};
  const { state } = context;

  if (
    state.shipmentTypesImpOrExp.value !== state.prevShipmentTypesImpOrExp.value &&
    (!state.deliveryReceiver.value || !state.pickupSender.value)
  ) {
    Object.assign(stateObj, swapSenderAndReceiverAddress(context));
  }

  termsOfDeliveryValue(context, stateObj, state.deliveryCountry.value);

  Object.assign(stateObj, resetProducts());

  if (params.name === 'shipmentRange' && params.value === DOMESTIC_SHIPMENT_RANGE) {
    Object.assign(stateObj, resetDangerousGoodsWhenSwitchedToDomestic(state));
    Object.assign(stateObj, resetPhoneNumberWhenSwitchedToDomestic(state));
  }

  if (params.name === 'shipmentRange') {
    stateObj.accounts = filterAccountsBasedOnShipmentRange(state.originalAccounts, params.value);
  }

  context.updateState(stateObj);
};

const resetDangerousGoodsWhenSwitchedToDomestic = state => {
  const shipmentDetailsRows = JSON.parse(JSON.stringify(state.shipmentDetailsRows));

  shipmentDetailsRows.forEach(shipmentDetailRow => {
    shipmentDetailRow.dangerousGoods.value = false;
    shipmentDetailRow.dangerousGoodGroup = JSON.parse(JSON.stringify(config.dangerousGoodGroup));
  });

  return { shipmentDetailsRows };
};

export const fillPickupAddress = async () => {
  const resultMap = {};

  await apiRequest(apiLinks.getUserData, 'GET').then(result => {
    if (result.status === CONST.STATUS_OK && result.data && result.data.user) {
      resultMap.data = result.data.user;
    }
  });

  return resultMap;
};

export const afterCountry = (context, params) => {
  const stateObj = {};
  const selectedToCountry = context.state.deliveryCountry.value;
  const isInternational = context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE;
  const toCountries = context.state.internationalShippingCountries;

  if (isInternational) {
    const customsCountry = toCountries.find((toCountry, index) => {
      return toCountry.countryCode === selectedToCountry;
    });

    termsOfDeliveryValue(context, stateObj, context.state.deliveryCountry.value);
    stateObj['countryBasedCustoms'] = customsCountry && customsCountry.customs;
  }

  if (params && params.name === 'pickupCountry') {
    changeCountryCodePhonePrefix(context, params.value, 'pickupPhoneNumbers');
  }
  if (params && params.name === 'deliveryCountry') {
    changeCountryCodePhonePrefix(context, params.value, 'deliveryPhoneNumbers');
  }

  stateObj['differentPickupCountry'] = JSON.parse(JSON.stringify(context.state.differentPickupCountry));
  stateObj['differentDeliveryCountry'] = JSON.parse(JSON.stringify(context.state.differentDeliveryCountry));
  stateObj['differentPickupCountry'].value = context.state.pickupCountry.value;
  stateObj['differentDeliveryCountry'].value = context.state.deliveryCountry.value;

  if (stateObj['differentPickupCountry'].value) {
    changeCountryCodePhonePrefix(context, stateObj['differentPickupCountry'].value, 'differentPickupPhoneNumbers');
  }
  if (stateObj['differentDeliveryCountry'].value) {
    changeCountryCodePhonePrefix(context, stateObj['differentDeliveryCountry'].value, 'differentDeliveryPhoneNumbers');
  }

  updatePostalCode(context.state, context.state.pickupCountry.value, context.state.deliveryCountry.value, stateObj);

  context.updateState(stateObj);
};

export const showTermsOfDeliveryDropdown = context => {
  const opts = readState(context.state);
  const show = opts.isInternational;
  return show;
};

const resetProducts = () => {
  return {
    product: { value: '', error: false },
    additionalServices: [],
  };
};

export const checkSenderPayer = (context, params) => {
  const condition = displayDropdownWithPayersWithoutSender(context, params);
  const map = {};

  if (condition) {
    if (context.state.shipmentPayer.value === SENDER_PAYER) {
      map.shipmentPayer = {};
      map.deliveryAddressResidential = {};
      map.shipmentPayer.value = RECEIVER_PAYER;
      map.deliveryAddressResidential.value = false;
      context.updateState(map);
    }
  }
};

export const readState = state => {
  const isDomestic = state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE,
    isInternational = state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE,
    isImport = state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE,
    isExport = state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE,
    isSenderPayer = state.shipmentPayer.value === SENDER_PAYER,
    isReceiverPayer = state.shipmentPayer.value === RECEIVER_PAYER,
    isThirdPartyPayer = state.shipmentPayer.value === THIRD_PARTY_PAYER;

  return {
    isDomestic,
    isInternational,
    isImport,
    isExport,
    isSenderPayer,
    isReceiverPayer,
    isThirdPartyPayer,
  };
};

export const termsOfDeliveryValue = (context, stateObj, deliveryCountry) => {
  const opts = readState(context.state);
  const casePayerCode = payerCode(opts);

  if (casePayerCode !== 'case9') {
    setAccountInfo(casePayerCode, context.state, stateObj);
  }

  stateObj['termsOfDelivery'] = {};
  stateObj['termsOfDelivery'].value = '';
  stateObj['termsOfDelivery'].error = false;
  stateObj['termsOfDelivery'].isRequired = false;

  return stateObj;
};

export const setAccountInfo = (casePayerCode, state, stateObj) => {
  if (casePayerCode === 'case1' || casePayerCode === 'case2') {
    stateObj.accountNumber = { value: state.accountNumber.value, error: false, display: true, isSelect: true };
    stateObj.receiverNumber = { value: '', error: false, display: false, isSelect: false };
    stateObj.thirdPartyNumber = { value: '', error: false };
  } else if (casePayerCode === 'case3' || casePayerCode === 'case8') {
    stateObj.accountNumber = { value: state.accountNumber.value, error: false, display: true, isSelect: true };
    stateObj.receiverNumber = { value: '', error: false, display: false, isSelect: false };
    stateObj.thirdPartyNumber = { value: state.thirdPartyNumber.value, error: false, display: true, isSelect: false };
  } else if (casePayerCode === 'case4') {
    stateObj.accountNumber = { value: '', error: false, display: false, isSelect: false };
    stateObj.receiverNumber = { value: state.receiverNumber.value, error: false, display: true, isSelect: true };
    stateObj.thirdPartyNumber = { value: '', error: false, display: false, isSelect: false };
  } else if (casePayerCode === 'case5') {
    stateObj.accountNumber = { value: '', error: false, display: false, isSelect: false };
    stateObj.receiverNumber = { value: state.receiverNumber.value, error: false, display: true, isSelect: true };
    stateObj.thirdPartyNumber = { value: state.thirdPartyNumber.value, error: false, display: true, isSelect: false };
  } else if (casePayerCode === 'case6') {
    stateObj.accountNumber = { value: state.accountNumber.value, error: false, display: true, isSelect: true };
    stateObj.receiverNumber = { value: '', error: false, display: false, isSelect: false };
    stateObj.thirdPartyNumber = { value: '', error: false, display: false };
  } else if (casePayerCode === 'case7') {
    [stateObj.accountNumber, stateObj.receiverNumber] = domesticAndReceiverPay(state);
    stateObj.thirdPartyNumber = { value: '', error: false, display: false };
  }

  stateObj.accountNumber.isRequired =
    state.shipmentPayer.value === SENDER_PAYER ||
    (state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE &&
      state.shipmentPayer.value === RECEIVER_PAYER &&
      !state.pickupAddressResidential.value) ||
    (state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
      state.shipmentTypesImpOrExp.value === EXPORT_SHIPMENT_TYPE);

  stateObj.receiverNumber.isRequired =
    (state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE &&
      state.shipmentTypesImpOrExp.value === IMPORT_SHIPMENT_TYPE) ||
    (state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE && state.shipmentPayer.value === RECEIVER_PAYER);

  stateObj.thirdPartyNumber.isRequired =
    state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE && state.shipmentPayer.value === THIRD_PARTY_PAYER;

  // reset account info if select and not present in account list
  resetAccountInfoIfNotPresentInAccount(state, stateObj);

  // set disabled and error to false
  stateObj.accountNumber.disabled =
    state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE && state.pickupAddressResidential.value ? true : false;
  stateObj.accountNumber.error = false;

  stateObj.receiverNumber.disabled = false;
  stateObj.receiverNumber.error = false;

  stateObj.thirdPartyNumber.disabled = false;
  stateObj.thirdPartyNumber.error = false;
};

const domesticAndReceiverPay = state => {
  const stateToSet = {};

  stateToSet.accountNumber = { value: state.accountNumber.value, error: false, display: true };
  stateToSet.receiverNumber = { value: state.receiverNumber.value, error: false, display: true };

  // special case :  Domestic -  Receiver Payer
  // case 1: sender selected - receiver free text
  // case 2: receiver selected - sender free text
  stateToSet.receiverNumber.isSelect = !checkIfEnteredAccountIsPresentInAccountList(state, state.accountNumber);
  stateToSet.accountNumber.isSelect = !checkIfEnteredAccountIsPresentInAccountList(state, state.receiverNumber);

  // case 3: when sender and receiver both are filled - sender is select , receiver is text input
  if (!stateToSet.receiverNumber.isSelect && !stateToSet.accountNumber.isSelect) {
    stateToSet.accountNumber.isSelect = true;
  }

  return [stateToSet.accountNumber, stateToSet.receiverNumber];
};

const resetAccountInfoIfNotPresentInAccount = (state, stateObj) => {
  if (stateObj.accountNumber.isSelect && !checkIfEnteredAccountIsPresentInAccountList(state, stateObj.accountNumber)) {
    stateObj.accountNumber.value = '';
  }

  if (
    stateObj.receiverNumber.isSelect &&
    !checkIfEnteredAccountIsPresentInAccountList(state, stateObj.receiverNumber)
  ) {
    stateObj.receiverNumber.value = '';
  }
  if (
    stateObj.thirdPartyNumber.isSelect &&
    !checkIfEnteredAccountIsPresentInAccountList(state, stateObj.thirdPartyNumber)
  ) {
    stateObj.thirdPartyNumber.value = '';
  }
};

export const payerCode = opts => {
  if (opts.isInternational && opts.isExport && opts.isReceiverPayer) return 'case1';
  if (opts.isInternational && opts.isExport && opts.isSenderPayer) return 'case2';
  if (opts.isInternational && opts.isExport && opts.isThirdPartyPayer) return 'case3';
  if (opts.isInternational && opts.isImport && opts.isReceiverPayer) return 'case4';
  if (opts.isInternational && opts.isImport && opts.isThirdPartyPayer) return 'case5';
  if (!opts.isInternational && opts.isSenderPayer) return 'case6';
  if (!opts.isInternational && opts.isReceiverPayer) return 'case7';
  if (!opts.isInternational && opts.isThirdPartyPayer) return 'case8';
  if (opts.isInternational && opts.isImport && opts.isSenderPayer) return 'case9';
};

export const displayDropdownWithPayersWithoutSender = (context, params) => {
  const exportImport = params !== undefined ? params.value : context.state.shipmentTypesImpOrExp.value;

  return context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE && exportImport === IMPORT_SHIPMENT_TYPE;
};

export const getPickupPhoneNumber = phoneNumber => {
  const { code, number } = preparePhoneNumber(phoneNumber);
  return phoneNumber
    ? [
        {
          code,
          error: false,
          isRequired: false,
          number,
        },
      ]
    : [
        {
          code: config.defaultCountryPhoneCode,
          error: false,
          isRequired: false,
          number: '',
        },
      ];
};

export const renderDifferentDeliveryAddress = ({ context, t, showAddressPickup, residentialToDisabled }) => {
  return (
    <>
      <h4 className="margin-bottom-1 frc__push-bottom frc__red-lined-title">{t(`general|Delivery Address`)}</h4>
      <div className="frc__generic--background-white frc__generic-section--wrapper">
        <Checkbox
          labelAfter={
            '&nbsp;<span class="has-tooltip"> ' +
            '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
            t(`general|Different Delivery residential address tooltip`) +
            '</div></div>' +
            '</span>'
          }
          label={t('general|This is a residential address')}
          name="differentDeliveryAddressResidential"
          isRequired={false}
          checked={context.state.differentDeliveryAddressResidential.value}
          context={context}
          config={config}
          CONST={CONST}
          afterUpdate={checkContactEmail}
          disabled={residentialToDisabled}
        />
        <div className="frc__address-book-btn l-grid--w-100pc-s">
          <Button
            id="addressBook"
            dataTestId="diffReceiverAddressBookButton"
            submit={() => showAddressPickup(CONST.DIFFERENT_RECEIVER)}
            className="base-button"
            label={t(`general|Address Book`)}
          />
        </div>

        <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            regEx={config.regEx.everything}
            label={t('general|Receiver')}
            name="differentDeliveryReceiver"
            isRequired={false}
            value={context.state.differentDeliveryReceiver.value}
            lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
            cutTextLimit={config.maxDefaultInputLength}
          />

          {context.state.differentDeliveryReceiver.error ? (
            <Error
              name="differentDeliveryReceiver"
              className="frc__input--error"
              message={
                context.state.differentDeliveryAddressResidential.value
                  ? t('general|errors|Invalid Length Error', {
                      name: 'name',
                      max: config.maxDefaultInputLength,
                    })
                  : t('general|errors|Company Error', {
                      max: config.maxDefaultInputLength,
                    })
              }
            />
          ) : (
            ''
          )}
        </div>

        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
          <div className=" l-grid--w-100pc-s">
            <Input
              context={context}
              config={config}
              CONST={CONST}
              regEx={config.regEx.everything}
              label={t('general|labels|inputs|Street')}
              name="differentDeliveryStreet"
              isRequired={false}
              value={context.state.differentDeliveryStreet.value}
              lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
              cutTextLimit={config.maxDefaultInputLength}
            />
            {context.state.differentDeliveryStreet.error ? (
              <Error
                name="differentDeliveryStreet"
                className="frc__input--error"
                message={t('general|errors|Street Error', { max: config.maxDefaultInputLength })}
              />
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
          <div className=" l-grid--w-100pc-s l-grid--w-50pc-m">
            <PostalCodeInput
              value={context.state.differentDeliveryPostalCode.value}
              name="differentDeliveryPostalCode"
              isRequired
              countryCode={context.state.deliveryCountry.value}
              error={
                context.state.differentDeliveryPostalCode.error
                  ? POSTALCODE_ERRORS.validationError
                  : context.state?.deliverToDifferentAddress?.value && context.state?.destinationPostalCodeError
                  ? POSTALCODE_ERRORS.apiError
                  : undefined
              }
              context={context}
              config={config}
              CONST={CONST}
            />
          </div>
          <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
            <Input
              context={context}
              config={config}
              CONST={CONST}
              regEx={config.regEx.everything}
              label={t('general|labels|inputs|City')}
              name="differentDeliveryCity"
              isRequired={false}
              value={context.state.differentDeliveryCity.value}
              lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
              cutTextLimit={config.maxDefaultInputLength}
            />
            {context.state.differentDeliveryCity.error ? (
              <Error
                name="differentDeliveryCity"
                className="frc__input--error"
                message={t('general|errors|City Error', { max: config.maxDefaultInputLength })}
              />
            ) : (
              ''
            )}
            {context.state.deliverToDifferentAddress.value
              ? context.state.postalCodeState.destinationCityError && (
                  <Error
                    name="deliverToDifferentAddressdestinationCityError"
                    className="frc__input--error"
                    message={t('general|errors|City API Error', {
                      max: config.maxDefaultInputLength,
                    })}
                  />
                )
              : ''}
          </div>
        </div>

        <div className=" l-grid--w-100pc-s frc__generic-row--wrapper ">
          <Select
            selectClass="l-grid--w-100pc-s"
            label={t(`general|Country`)}
            name="differentDeliveryCountry"
            isRequired={false}
            values={
              context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE
                ? allCountries
                : config.domesticCountries
            }
            value={context.state.differentDeliveryCountry.value?.toUpperCase()}
            context={context}
            config={config}
            CONST={{
              API_DOMAIN: 'countries',
            }}
            disabled={true}
          />
          {context.state.differentDeliveryCountry.error ? (
            <Error name="differentDeliveryCountry" className="frc__input--error" message={t(`general|Country Error`)} />
          ) : (
            ''
          )}
        </div>
      </div>
      <p className="frc__contact-details-label" />

      <div className="frc__generic--background-white frc__generic-section--wrapper">
        <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            regEx={config.regEx.everything}
            label={t(`general|Contact Name`)}
            name="differentDeliveryName"
            isRequired={false}
            value={context.state.differentDeliveryName.value}
            lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
            cutTextLimit={config.maxDefaultInputLength}
          />
          {context.state.differentDeliveryName.error ? (
            <Error
              name="differentDeliveryName"
              className="frc__input--error"
              message={t('general|errors|Contact Name Error', { max: config.maxDefaultInputLength })}
            />
          ) : (
            ''
          )}
        </div>

        <div className="l-grid--w-100pc-s frc__generic-row--wrapper frc__generic-position-relative">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            label={t(`general|Contact E-mail`)}
            name="differentDeliveryEmail"
            value={context.state.differentDeliveryEmail.value}
            lengthCheck={[RegExp(`^.{0,${config.maxEmailLength}}$`)]}
            cutTextLimit={config.maxEmailLength}
            isRequired={false}
          />
          {context.state.differentDeliveryEmail.error ? (
            <Error
              name="differentDeliveryEmail"
              className="frc__input--error"
              message={t('general|errors|E-mail Error', { chars: config.maxEmailLength })}
            />
          ) : (
            ''
          )}
          <Checkbox
            context={context}
            config={config}
            CONST={CONST}
            label={t('general|Send notification delivery')}
            name="differentDeliveryEmailConfirmation"
            isRequired={false}
            checked={context.state.differentDeliveryEmailConfirmation.value}
            className="frc__email-checkbox-confirmation"
          />
        </div>

        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
          {context.state.differentDeliveryPhoneNumbers.slice(0, 1).map((phoneNumber, ind) => (
            <PhoneNumber
              key={ind}
              context={context}
              config={config}
              CONST={CONST}
              index={ind}
              number={phoneNumber.number}
              code={phoneNumber.code}
              error={phoneNumber.error}
              groupName="differentDeliveryPhoneNumbers"
              isRequired={false}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export const updatePostalCode = (state, pickupCountry, deliveryCountry, stateObj) => {
  if (config.numberPostalCodeCountries.indexOf(pickupCountry) !== -1) {
    if (!testRegEx(state.pickupPostalCode.value, config.regEx.number)) {
      stateObj['pickupPostalCode'] = JSON.parse(JSON.stringify(state.pickupPostalCode));
      stateObj['pickupPostalCode'].value = '';
    }

    if (state.pickupFromDifferentAddress.value && !state.pickupAddressResidential.value) {
      if (!testRegEx(state.differentPickupPostalCode.value, config.regEx.number)) {
        stateObj['differentPickupPostalCode'] = JSON.parse(JSON.stringify(state.differentPickupPostalCode));
        stateObj['differentPickupPostalCode'].value = '';
      }
    }
  }
  if (config.numberPostalCodeCountries.indexOf(deliveryCountry) !== -1) {
    if (!testRegEx(state.deliveryPostalCode.value, config.regEx.number)) {
      stateObj['deliveryPostalCode'] = JSON.parse(JSON.stringify(state.deliveryPostalCode));
      stateObj['deliveryPostalCode'].value = '';
    }

    if (state.deliverToDifferentAddress.value && !state.deliveryAddressResidential.value) {
      if (!testRegEx(state.differentDeliveryPostalCode.value, config.regEx.number)) {
        stateObj['differentDeliveryPostalCode'] = JSON.parse(JSON.stringify(state.differentDeliveryPostalCode));
        stateObj['differentDeliveryPostalCode'].value = '';
      }
    }
  }
};

export const checkContactEmail = context => {
  const stateObj = {};
  const { state } = context;

  // Domestic - Payer: Receiver case
  const isDomesticPickupResidential =
    state.shipmentRange.value === DOMESTIC_SHIPMENT_RANGE &&
    (state.pickupAddressResidential.value || state.differentPickupAddressResidential.value);

  stateObj.accountNumber = JSON.parse(JSON.stringify(state.accountNumber));
  stateObj.receiverNumber = JSON.parse(JSON.stringify(state.receiverNumber));

  if (isDomesticPickupResidential) {
    // set sender account to free text
    stateObj.accountNumber.isSelect = false;
    stateObj.accountNumber.disabled = true;
    stateObj.accountNumber.isRequired = false;
    stateObj.accountNumber.error = false;

    // set receiver account to select
    stateObj.receiverNumber.isSelect = true;
    stateObj.receiverNumber.disabled = false;
    stateObj.receiverNumber.isRequired = true;

    // reset receiver value if not present in account array
    if (!checkIfEnteredAccountIsPresentInAccountList(state, stateObj.receiverNumber)) {
      stateObj.receiverNumber.value = '';
    }
  } else {
    termsOfDeliveryValue(context, stateObj, state.deliveryCountry.value);
  }

  context.updateState(stateObj);
};

const checkIfEnteredAccountIsPresentInAccountList = (state, accountEvaluation) => {
  return state.accounts.some(account => account.code === accountEvaluation.value);
};

export const renderDifferentPickUpAddress = ({ context, t, showAddressPickup, residentialFromDisabled }) => {
  return (
    <>
      <h4 className="margin-bottom-1 frc__push-bottom frc__red-lined-title">{t('general|Pickup Address')}</h4>
      <div className="frc__generic--background-white frc__generic-section--wrapper">
        <Checkbox
          labelAfter={
            '&nbsp;<span class="has-tooltip"> ' +
            '<div class="frc__tooltip-container has-icon icon-information color-DHL-red c-fcp-quote--info-container"><div class="frc__tooltip-container-flyout">' +
            t(`general|Different Pickup residential address tooltip`) +
            '</div></div>' +
            '</span>'
          }
          label={t('general|This is a residential address')}
          name="differentPickupAddressResidential"
          isRequired={false}
          checked={context.state.differentPickupAddressResidential.value}
          context={context}
          config={config}
          CONST={CONST}
          afterUpdate={checkContactEmail}
          disabled={residentialFromDisabled}
        />
        <div className="frc__address-book-btn l-grid--w-100pc-s">
          <Button
            id="addressBook"
            dataTestId="diffSenderAddressBookButton"
            submit={() => showAddressPickup(CONST.DIFFERENT_SENDER)}
            className="base-button"
            label={t(`general|Address Book`)}
          />
        </div>

        <div className="l-grid--w-100pc-s frc__generic-row--wrapper">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            regEx={config.regEx.everything}
            label={t('general|Sender')}
            name="differentPickupSender"
            isRequired={false}
            value={context.state.differentPickupSender.value}
            lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
            cutTextLimit={config.maxDefaultInputLength}
          />

          {context.state.differentPickupSender.error ? (
            <Error
              name="differentPickupSender"
              className="frc__input--error"
              message={
                context.state.differentPickupAddressResidential.value
                  ? t('general|errors|Invalid Length Error', {
                      name: 'name',
                      max: config.maxDefaultInputLength,
                    })
                  : t('general|errors|Company Error', {
                      max: config.maxDefaultInputLength,
                    })
              }
            />
          ) : (
            ''
          )}
        </div>
        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
          <div className=" l-grid--w-100pc-s">
            <Input
              context={context}
              config={config}
              CONST={CONST}
              regEx={config.regEx.everything}
              label={t('general|labels|inputs|Street')}
              name="differentPickupStreet"
              isRequired={false}
              value={context.state.differentPickupStreet.value}
              lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
              cutTextLimit={config.maxDefaultInputLength}
            />
            {context.state.differentPickupStreet.error ? (
              <Error
                name="differentPickupStreet"
                className="frc__input--error"
                message={t('general|errors|Street Error', { max: config.maxDefaultInputLength })}
              />
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper">
          <div className=" l-grid--w-100pc-s l-grid--w-50pc-m">
            <PostalCodeInput
              value={context.state.differentPickupPostalCode.value}
              name="differentPickupPostalCode"
              isRequired
              countryCode={context.state.pickupCountry.value}
              error={
                context.state.differentPickupPostalCode.error
                  ? POSTALCODE_ERRORS.validationError
                  : context.state?.pickupFromDifferentAddress?.value && context.state?.sourcePostalCodeError
                  ? POSTALCODE_ERRORS.apiError
                  : undefined
              }
              context={context}
              config={config}
              CONST={CONST}
            />
          </div>
          <div className="l-grid--w-100pc-s l-grid--w-50pc-m">
            <Input
              context={context}
              config={config}
              CONST={CONST}
              regEx={config.regEx.everything}
              label={t('general|labels|inputs|City')}
              name="differentPickupCity"
              isRequired={false}
              value={context.state.differentPickupCity.value}
              lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
              cutTextLimit={config.maxDefaultInputLength}
            />
            {context.state.differentPickupCity.error ? (
              <Error
                name="differentPickupCity"
                className="frc__input--error"
                message={t('general|errors|City Error', { max: config.maxDefaultInputLength })}
              />
            ) : (
              ''
            )}
            {context.state.pickupFromDifferentAddress.value
              ? context.state.postalCodeState.sourceCityError && (
                  <Error
                    name="pickupFromDifferentAddresssourceCityError"
                    className="frc__input--error"
                    message={t('general|errors|City API Error', {
                      max: config.maxDefaultInputLength,
                    })}
                  />
                )
              : ''}
          </div>
        </div>

        <div className=" l-grid--w-100pc-s frc__generic-row--wrapper">
          <Select
            selectClass="l-grid--w-100pc-s"
            label={t(`general|Country`)}
            name="differentPickupCountry"
            isRequired={false}
            values={
              context.state.shipmentRange.value === INTERNATIONAL_SHIPMENT_RANGE
                ? allCountries
                : config.domesticCountries
            }
            value={context.state.differentPickupCountry.value?.toUpperCase()}
            context={context}
            config={config}
            CONST={{
              API_DOMAIN: 'countries',
            }}
            disabled={true}
          />
          {context.state.differentPickupCountry.error ? (
            <Error name="differentPickupCountry" className="frc__input--error" message={t(`general|Country Error`)} />
          ) : (
            ''
          )}
        </div>
      </div>
      <p className="frc__contact-details-label" />

      <div className="frc__generic--background-white frc__generic-section--wrapper">
        <div className="l-grid--w-100pc-s frc__generic-row--wrapper ">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            regEx={config.regEx.everything}
            label={t(`general|Contact Name`)}
            name="differentPickupName"
            isRequired={false}
            value={context.state.differentPickupName.value}
            lengthCheck={[RegExp(`^.{0,${config.maxDefaultInputLength}}$`)]}
            cutTextLimit={config.maxDefaultInputLength}
          />
          {context.state.differentPickupName.error ? (
            <Error
              name="differentPickupName"
              className="frc__input--error"
              message={t('general|errors|Contact Name Error', { max: config.maxDefaultInputLength })}
            />
          ) : (
            ''
          )}
        </div>

        <div className="l-grid--w-100pc-s frc__generic-row--wrapper frc__generic-position-relative">
          <Input
            context={context}
            config={config}
            CONST={CONST}
            label={t(`general|Contact E-mail`)}
            name="differentPickupEmail"
            isRequired={false}
            value={context.state.differentPickupEmail.value}
            lengthCheck={[RegExp(`^.{0,${config.maxEmailLength}}$`)]}
            cutTextLimit={config.maxEmailLength}
          />
          {context.state.differentPickupEmail.error ? (
            <Error
              name="differentPickupEmail"
              className="frc__input--error"
              message={t('general|errors|E-mail Error', { chars: config.maxEmailLength })}
            />
          ) : (
            ''
          )}
          <Checkbox
            context={context}
            config={config}
            CONST={CONST}
            label={t('general|Send notification pickup')}
            name="differentPickupEmailConfirmation"
            isRequired={false}
            checked={context.state.differentPickupEmailConfirmation.value}
            className="frc__email-checkbox-confirmation"
          />
        </div>

        <div className="l-grid l-grid--w-100pc-s frc__generic-row--wrapper ">
          {context.state.differentPickupPhoneNumbers.slice(0, 1).map((phoneNumber, ind) => (
            <PhoneNumber
              key={ind}
              index={ind}
              context={context}
              config={config}
              CONST={CONST}
              number={phoneNumber.number}
              code={phoneNumber.code}
              error={phoneNumber.error}
              groupName="differentPickupPhoneNumbers"
              isRequired={false}
            />
          ))}
        </div>
      </div>
    </>
  );
};
