/**
 *
 * Used in class components or functions outside of React (helpers).
 */
export const preparePhoneNumber = (phoneNumber?: string) => {
  const cutPhoneByDash = phoneNumber?.split('-');
  if (!cutPhoneByDash) {
    return {
      code: '',
      number: '',
    };
  }
  return {
    code: cutPhoneByDash[0],
    number: cutPhoneByDash.slice(1).join('-') || '',
  };
};

/**
 * Prepared for future use in functional components
 */
export const usePhoneNumber = (phoneNumber?: string) => preparePhoneNumber(phoneNumber);
