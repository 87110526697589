import React, { Component } from 'react';
import './ScrollBar.css';
import { debounce } from 'globals/utils/debounce';

export default class ScrollBar extends Component {
  prevScroll = 0;

  constructor(props) {
    super(props);
    this.executeCall = debounce(this.executeCall, 500);
  }

  componentDidMount() {
    const { position } = this.props;

    if (position) {
      this.setScrollPosition(position);
    }
  }

  componentDidUpdate(prevProps) {
    const { position } = this.props;

    if (position !== prevProps.position) {
      this.setScrollPosition(position);
    }
  }

  handleScrollerRef = reference => {
    this.scroller = reference;
  };

  setScrollPosition(position = 0) {
    if (this.props.horizontal) {
      this.scroller.scrollLeft = position;
    } else {
      this.scroller.scrollTop = position;
    }

    this.prevScroll = position;
  }

  executeCall(scrolledDown, bottomEdge, scrolledUp, topEdge) {
    const { onReachTop, onReachBottom } = this.props;

    if (scrolledDown >= bottomEdge) {
      onReachBottom();
    } else if (scrolledUp <= topEdge) {
      onReachTop();
    }
  }

  handleVerticalScroll = () => {
    const { firstChild, lastChild, scrollTop, offsetTop, offsetHeight } = this.scroller;

    const topEdge = firstChild.offsetTop;
    const bottomEdge = lastChild.offsetTop + lastChild.offsetHeight;
    const scrolledUp = scrollTop + offsetTop;
    const scrolledDown = scrolledUp + offsetHeight;

    this.executeCall(scrolledDown, bottomEdge, scrolledUp, topEdge);
  };

  handleScroll = () => {
    //const { onScroll } = this.props;
    let scrolledTo = 0;

    this.handleVerticalScroll();
    scrolledTo = this.scroller.scrollTop;
    //onScroll(scrolledTo, this.prevScroll);
    this.prevScroll = scrolledTo;
  };

  render() {
    const { children } = this.props;

    return (
      <div ref={this.handleScrollerRef} className="frc__display-scroll-parent" onScroll={this.handleScroll}>
        {children}
      </div>
    );
  }
}
