import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import { config, CONST } from '../../data-config';
import { Error } from 'components';
import ShipmentDetailsRow from './ShipmentDetailsRow/ShipmentDetailsRow';
import { ShipmentRowsSummary } from '../../../components/ShipmentRowsSummary/ShipmentRowsSummary';
import { resetDeliveryOptions } from '../../helpers/resetState';

import {
  addShipmentDetailsRow,
  chargeableWeightValidation,
  checkIfProductsWithSpecialInputValidation,
  checkSubmit,
  getQuotePriceForPreSelectedProduct,
  postMatchedProducts,
  prepareUserData,
  removeShipmentDetailsRow,
  setDeliveryDateAndPrice,
} from '../EnterShipmentDetails/EnterShipmentDetails-helpers';
import { analytics } from 'globals/utils/analytics';
import getBaseUrl from 'globals/utils/getUrlBase';
import { disableOtherTabs } from '../AppTabs/AppTabs-helpers';
import { setCookie } from 'globals/utils/requests';
import { withQueryClient } from '../../../utils/withQueryClient';
import { userQueryKey } from '../../../hooks/api/useGetUser';
import { Stack } from '@mui/material';

import './EnterShipmentDetails.css';
import {
  setShipmentDimensionsForProductShowAll,
  setShipmentDimensionsForProduct,
} from '../../../globals/helpers/farmapi-validations';
import { ChatWithUs } from '../../../components/fcp-components/ChatWithUs';
import { prefilterShipmentProducts } from '../../../globals/helpers/product';
import { connect } from 'react-redux';

import { setProductPickupDates } from '../../../store/quoteSlice';
import { convertProductDatesToIsoStrings } from '../../../utils/products';
import { trackGetQuote, TrackGetQuoteStep } from '../../helpers/journeyTracking';

class EnterShipmentDetails extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      nextBtnLoader: false,
      goNextError: false,
      generalError: false,
      noContent: false,
      payerCodeError: false,
      globalColliError: false,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount = () => {
    const stateObject = {};
    const context = this.props.context;
    const routeContext = this.props.routeContext;
    const favouriteColliTypes = this.props.queryClient.getQueryData([userQueryKey])?.colliTypes;
    this._isMounted = true;

    const prefilteredProducts = prefilterShipmentProducts({
      productList: routeContext.state.productSpecifications
        ? Object.values(routeContext.state.productSpecifications)
        : null,
      shipmentData: {
        deliveryResidential: routeContext.state.deliveryAddressResidential.value,
        pickupResidential: routeContext.state.pickupAddressResidential.value,
        shipmentRange: routeContext.state.shipmentRange.value,
      },
    });

    // Set dimensions for the collitypes - specific or global ones
    if (routeContext.state.productsRangeAll.value) {
      [stateObject.dimensions, stateObject.shipmentRowsNumberMax] = setShipmentDimensionsForProductShowAll(
        routeContext.state,
        config,
        prefilteredProducts,
      );
    } else {
      [stateObject.dimensions, stateObject.shipmentRowsNumberMax] = setShipmentDimensionsForProduct(
        routeContext.state,
        config,
        routeContext.state.selectedProduct?.value,
      );
    }

    // Preserves a copy of original limits for later use
    stateObject.dimensionsOriginal = stateObject.dimensions;

    // In case of selected product, checks if it one with additional local validation (like DHL Home delivery, Stycke ...)
    stateObject.isProductWithSpecialInputValidation = checkIfProductsWithSpecialInputValidation(routeContext.state);

    context.updateState(stateObject, () => {
      // Adds new empty row in case of comming from #1 screen
      if (context.state.shipmentDetailsRows && context.state.shipmentDetailsRows.length === 0) {
        addShipmentDetailsRow(context, routeContext, favouriteColliTypes);
      }

      // Resets #3 screen
      resetDeliveryOptions(this.props.deliveryContext);
    });

    trackGetQuote(this.props.journeyId, TrackGetQuoteStep.SHIPMENT_DETAILS);

    window.scrollTo(0, 0);
  };

  update = (params, context) => {
    params.groupName = 'shipmentDetailsRows';
    context.updateGroupOfFields(params);
  };

  getErrorMessage = t => {
    const caseNoProductError = this.state.noContent ? 'noProductError' : false;
    const caseNextError = this.state.goNextError ? 'goNextError' : false;
    const caseGeneralError = this.state.generalError ? 'generalError' : false;
    const casePayerCodeError = this.state.payerCodeError ? 'payerCodeError' : false;
    const caseChargeableWeightError = this.state.chargeableWeightError ? 'chargeableWeightError' : false;
    const globalColliError = this.state.globalColliError ? 'globalColliError' : false;

    const transactionId = this.props.routeContext.state.transactionId;
    const error =
      globalColliError ||
      caseNoProductError ||
      caseNextError ||
      caseGeneralError ||
      casePayerCodeError ||
      caseChargeableWeightError;
    let message = '';

    switch (error) {
      case 'globalColliError':
        message = t('general|errors|globalColliError');
        break;
      case 'noProductError':
        message =
          t('general|errors|No product matches were found') +
          ' <br> ' +
          t('general|errors|Please change criteria') +
          ' <br> ' +
          t('general|ID') +
          ': ' +
          transactionId;
        break;
      case 'goNextError':
        message =
          t('general|errors|General error') +
          '. <br> ' +
          t('general|Try again or call technical support.') +
          ' ' +
          t('general|or') +
          ' ' +
          <ChatWithUs /> +
          ' <br> ' +
          t('general|ID') +
          ': ' +
          transactionId;
        break;
      case 'generalError':
        message = t('general|errors|Please fill properly all necessary fields');
        break;
      case 'chargeableWeightError':
        message =
          t(`quoteTool|Chargeable weight below is required.`) +
          t(`quoteTool|Please try different a product or contact us.`);
        break;
      case 'payerCodeError':
        message =
          t('general|errors|No product matches were found for selected payer code') +
          ' <br> ' +
          t('general|errors|Please change criteria') +
          ' <br> ' +
          t('general|ID') +
          ': ' +
          transactionId;
        break;
      default:
        return;
    }

    return <Error name="quoteMultipleShipmentDetailsError" message={message} />;
  };

  formDataForProductMatch = (routeState, shipmentState) => {
    let data = {};
    let payload;
    let shipmentData = {};

    data.shipmentDetailsRows = shipmentState.shipmentDetailsRows;
    data.totals = shipmentState.totals;
    data.pickupCountry = routeState.pickupCountry;
    data.pickupPostalCode = routeState.pickupPostalCode;
    data.pickupAddressResidential = routeState.pickupAddressResidential;
    data.deliveryCountry = routeState.deliveryCountry;
    data.deliveryPostalCode = routeState.deliveryPostalCode;
    data.deliveryAddressResidential = routeState.deliveryAddressResidential;
    data = JSON.parse(JSON.stringify(data));
    data.accountNumber = routeState.accountNumber?.value;
    data.productsRangeAll = routeState.productsRangeAll;
    data.productCode = routeState.selectedProduct?.value || null;
    shipmentData = prepareUserData(data);
    payload = shipmentData.shipmentDetails;
    payload.payerCode = routeState.payerCode;

    data.totals.shipmentDetailsRows.numberOfFullPallets = { value: payload.numberOfFullPallets };
    data.totals.shipmentDetailsRows.numberOfHalfPallets = { value: payload.numberOfHalfPallets };

    const params = {
      body: payload,
      headers: {
        Referer: getBaseUrl(null, 1),
      },
    };
    params.body.transactionId = routeState.transactionId;

    return params;
  };

  setDeliveryDateAndPriceForMatchedProducts = (routeStateProducts, productMatchesResult, stateToSet, routeState) => {
    const matchedProducts = setDeliveryDateAndPrice(routeStateProducts, productMatchesResult, routeState);

    if (matchedProducts.length === 0) stateToSet.noContent = true;

    return matchedProducts;
  };

  getMatchedProducts = async (stateToSet, routeState, shipmentState, deliveryContext) => {
    // API call to get products
    const productMatchResult = await postMatchedProducts(this.formDataForProductMatch(routeState, shipmentState));

    // Set delivery dates, prices and updates
    if (productMatchResult.status === CONST.STATUS_OK && this._isMounted) {
      const oneProductSelected =
        this.props.routeContext.state.productsRangeOne?.value === true &&
        this.props.routeContext.state.selectedProduct?.value;

      const productPickupDates = oneProductSelected
        ? {
            [this.props.routeContext.state.selectedProduct?.value]:
              productMatchResult?.data?.pickupDates[this.props.routeContext.state.selectedProduct?.value],
          }
        : productMatchResult?.data?.pickupDates;

      if (oneProductSelected && !productPickupDates[this.props.routeContext.state.selectedProduct?.value]) {
        stateToSet.noContent = true;
      } else {
        this.props.setProductPickupDates(convertProductDatesToIsoStrings(productPickupDates));

        deliveryContext.updateState({
          matchedProducts: this.setDeliveryDateAndPriceForMatchedProducts(
            routeState.products,
            productMatchResult.data,
            stateToSet,
            routeState,
          ),
          quotePriceMap: {},
          allProductPrice: productMatchResult.data ? productMatchResult.data.prices : {},
        });
      }
    } else if (productMatchResult.status === CONST.STATUS_NO_CONTENT) {
      stateToSet.noContent = true;
    } else {
      stateToSet.goNextError = true;
    }
  };

  getStateToSet = () => {
    return {
      goNextError: false,
      noContent: false,
      nextBtnLoader: false,
      chargeableWeightError: false,
    };
  };

  onGoClick = async () => {
    const { context, routeContext, deliveryContext } = this.props;
    const { hasError, isGlobalColliError } = checkSubmit(context, routeContext);
    if (!hasError) {
      const stateToSet = {
        goNextError: false,
        noContent: false,
        nextBtnLoader: false,
        chargeableWeightError: false,
        globalColliError: false,
      };

      // Debouncing
      disableOtherTabs(this.props, true, [2], config.tabs.tab2);

      this.setState({ nextBtnLoader: true });

      // If specific product selected on step #1
      if (routeContext.state.productsRangeOne.value) {
        // Check if selected product requires Chargeable weight validation
        if (config.minChargeableWeightCheckProductCodes.indexOf(routeContext.state.selectedProduct.value) !== -1) {
          stateToSet.chargeableWeightError = await chargeableWeightValidation(context.state, routeContext.state);
        }

        if (!stateToSet.chargeableWeightError) {
          // Make quoteforprice API call (DHL PALL special handling included)
          const deliveryContextStateToSet = await getQuotePriceForPreSelectedProduct(
            context.state,
            routeContext.state,
            stateToSet,
          );

          deliveryContext.updateState({ ...deliveryContextStateToSet });
        }
      }
      await this.getMatchedProducts(stateToSet, routeContext.state, context.state, deliveryContext);

      if (this._isMounted) {
        stateToSet.nextBtnLoader = false;
        stateToSet.generalError = false;
        this.setState(stateToSet);
      }

      if (!stateToSet.goNextError && !stateToSet.noContent && !this.state.chargeableWeightError) {
        // Update statistics
        analytics('step 2', CONST.ANALYTICS);

        //Save shipment type to cookie
        setCookie('quoteShipmentType', context.state.shipmentDetailsRows[0].shipmentType.value, CONST.ONE_MONTH, 'h');

        // Switch to #3 screen
        this.props.nextTab(config.tabs.tab3);
      } else {
        // Debouncing
        disableOtherTabs(this.props, false, [2], config.tabs.tab2);
      }
    }
    // Form validation didn't pass
    else {
      this.setState({
        generalError: true,
        globalColliError: isGlobalColliError,
      });
    }
  };

  render() {
    return (
      <>
        <Translation>
          {t => (
            <>
              <div className="frc__enter-shipment-details--wrapper frc__generic-section--wrapper">
                <div className="frc__generic--field-wrapper">
                  <h4 className="margin-bottom-1 frc__red-lined-title ">
                    {t(`general|Enter Shipment Details`)}
                    <span className="has-tooltip-label">
                      <div className="frc__tooltip-title-container has-icon icon-information color-DHL-red c-fcp-quote--info-container frc__tooltip_title">
                        <div className="frc__tooltip-container-flyout">
                          {t(`general|Enter Shipment Details Tooltip`)} <br /> <br />
                          {t(`general|Enter Shipment Details Tooltip second line`)}
                        </div>
                      </div>
                    </span>
                  </h4>

                  <div
                    id="shipmentDetailsRowsContainer"
                    data-testid="shipmentDetailsRowsContainer"
                    className={`frc__enter-shipment-details-rows--wrapper ${
                      this.props.context.state.shipmentDetailsRows.length >= 5
                        ? 'frc__enter-shipment-details-rows--wrapper-fixed-height'
                        : ''
                    }`}
                  >
                    <Stack spacing={1}>
                      {this.props.context.state.shipmentDetailsRows.map((shipmentRow, ind) => (
                        <ShipmentDetailsRow
                          key={ind}
                          index={ind}
                          shipmentRow={shipmentRow}
                          removeOnParent={index => removeShipmentDetailsRow(index, this.props.context)}
                          error={shipmentRow.error}
                          context={this.props.context}
                          routeContext={this.props.routeContext}
                        />
                      ))}
                    </Stack>
                    <div
                      className={
                        'frc__generic--loader-overlay' +
                        (this.props.context.state.loaders.shipmentDetails.value ? ' is-loading ' : '')
                      }
                    />
                  </div>

                  <div
                    className={
                      'frc__generic--field-wrapper ' +
                      (this.props.context.state.loaders.shipmentDetails.value ? ' l-invisible ' : '')
                    }
                  >
                    {this.props.context.state.shipmentDetailsRows &&
                    this.props.context.state.shipmentDetailsRows.length <
                      this.props.context.state.shipmentRowsNumberMax ? (
                      <button
                        id="addShipmentDetailsRowBtn"
                        data-testid="addShipmentDetailsRowBtn"
                        onClick={() =>
                          addShipmentDetailsRow(
                            this.props.context,
                            this.props.routeContext,
                            this.props.queryClient.getQueryData([userQueryKey])?.colliTypes,
                          )
                        }
                        className={
                          'base-button base-button--white base-button--wide frc__generic-button frc__add-button ' +
                          (this.props.context.state.shipmentDetailsRows &&
                          this.props.context.state.shipmentDetailsRows.length <
                            this.props.context.state.shipmentRowsNumberMax
                            ? ''
                            : ' disabled ')
                        }
                      >
                        <span className="has-icon icon-plus frc__button-icon--align">
                          {t(`general|Add another item of different size`)}
                        </span>
                      </button>
                    ) : (
                      this.props.context.state.shipmentRowsNumberMax !== 1 && (
                        <Error name="maxrowLimitReached" message={t('general|You have reached the limit of rows')} />
                      )
                    )}
                  </div>
                  <ShipmentRowsSummary context={this.props.context} />
                  <div className="frc__generic-row--wrapper">
                    <div className="l-grid l-grid--w-100pc-s l-grid--between-s">
                      <button
                        id="backToRouteBtn"
                        className={`base-button base-button--white l-grid--w-25pc-w  
                          ${this.state.nextBtnLoader ? ' disabled' : ''} `}
                        onClick={this.state.nextBtnLoader ? null : () => this.props.previousTab(config.tabs.tab1, 1)}
                      >
                        <span>{t(`general|Back to route`)}</span>
                      </button>
                      <button
                        id="goToDeliveryOptionsBtn"
                        data-testid="next-button"
                        className={
                          'base-button base-button--wide l-grid--w-25pc-w ' +
                          (this.state.nextBtnLoader ? ' is-loading--right ' : '')
                        }
                        onClick={this.onGoClick}
                        disabled={this.state.nextBtnLoader}
                      >
                        <span>{t('general|labels|buttons|Next Step')}</span>
                      </button>
                    </div>
                    <div className="l-grid--right-s">
                      {this.state.goNextError ||
                      this.state.noContent ||
                      this.state.payerCodeError ||
                      this.state.chargeableWeightError ||
                      this.state.generalError
                        ? this.getErrorMessage(t)
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Translation>
      </>
    );
  }
}

const QueryClientComponent = withQueryClient(EnterShipmentDetails);

const mapDispatchToProps = {
  setProductPickupDates,
};

export default connect(null, mapDispatchToProps)(QueryClientComponent);
