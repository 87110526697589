import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { globalConfig } from '../../../globals/data-config-global';
import { useGetAdrLanguagesQuery, useLazySearchDgItemsQuery } from '../../../store/api/fcpShipment';
import { ControlledTextField } from '../form-inputs';

import { ArrowDropDown, ArrowDropUp, Close } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button as FcpButton } from '../Button';
import { Loader } from '../Loader';
import { isExcludedDangerousItem } from './DangerousGoods';
import { DG_COLUMN_GAP, UN_NUMBER_WIDTH } from './DangerousGoods.constants';
import { DgRow } from './DgRow';

export const LookupTable = ({ unNumberInit, open, onClose, onSelect, dangerousGoodsExclusions }) => {
  const { t } = useTranslation();
  const [searchDgItems, searchResult] = useLazySearchDgItemsQuery();
  const adrLanguagesQuery = useGetAdrLanguagesQuery();

  const [sortByUnNumberAsc, setSortByUnNumberAsc] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);

  const dgItems = useMemo(() => {
    if (!searchResult?.data?.dgItems?.length) return [];

    const newDgItems = [...searchResult?.data?.dgItems];
    newDgItems.sort((a, b) => (sortByUnNumberAsc ? 1 : -1) * (a.unNumber - b.unNumber));

    return newDgItems;
  }, [searchResult?.data?.dgItems, sortByUnNumberAsc]);

  const { control, handleSubmit, getValues, formState } = useForm({
    mode: 'onBlur',
    defaultValues: {
      unNumber: unNumberInit,
      psn: '',
      languageCode: 'en',
    },
  });

  const onSubmit = async formData => {
    setShowValidationError(false);
    if (!formData.unNumber && !formData.psn) return;
    if (formData.unNumber && formData.psn) {
      setShowValidationError(true);
      return;
    }

    await searchDgItems(
      {
        ...(formData.unNumber && { unNumber: formData.unNumber }),
        ...(formData.psn && { psnSubstring: formData.psn }),
        languageCode: formData.languageCode,
      },
      true,
    );
  };

  // init lazy query data if we have unNumber in props
  useEffect(() => {
    if (searchResult.isUninitialized && unNumberInit) {
      searchDgItems({ unNumber: unNumberInit, languageCode: getValues('languageCode') }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        minWidth: '700px',
        '& > .MuiDialog-container > .MuiPaper-root': {
          p: 0,
        },
      }}
      id="dgLookupTable"
    >
      {/* HEADER */}
      <Stack
        spacing={2}
        sx={{
          bgcolor: '#F2F2F2',
          p: '24px 24px 12px 24px',
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <DialogTitle sx={{ p: 0 }}>{t('general|dangerousGoods|lookupTableTitle')}</DialogTitle>
          <IconButton onClick={onClose} size="medium" sx={{ p: 0 }} id="dgLookupCloseIcon">
            <Close sx={{ fontSize: 25 }} />
          </IconButton>
        </Stack>

        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: 'flex',
            gap: '24px',
            alignItems: 'flex-start',
          }}
        >
          <ControlledTextField
            control={control}
            label={t('general|UN Number')}
            fieldName="unNumber"
            rules={{
              pattern: {
                value: /^\d{4}$/,
                message: t('general|Dangerous Good Error', {
                  name: t('general|UN Number'),
                  max: globalConfig.dangerousGoodDimensions.max_un_number,
                }),
              },
            }}
            TextFieldProps={{
              inputProps: { maxLength: globalConfig.dangerousGoodDimensions.max_un_number },
              sx: { maxWidth: '200px' },
              id: 'dgLookupUnNumber',
            }}
            inputRegex={/^\d*$/}
          />
          <Box
            sx={{
              pt: '21px',
              width: 'fit-content',
              flexShrink: 0,
            }}
          >
            <Typography
              sx={{
                height: '48px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              &mdash; {t('general|or')} &mdash;
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <ControlledTextField
              control={control}
              label={t('general|dangerousGoods|psn')}
              fieldName="psn"
              TextFieldProps={{
                sx: {
                  maxWidth: '300px',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderRightWidth: '0.5px',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                },
                id: 'dgLookupPsn',
              }}
            />
            <Controller
              control={control}
              name="languageCode"
              render={({ field: { value, onChange, onBlur }, fieldState: { invalid } }) => (
                <TextField
                  select
                  value={value}
                  onBlur={onBlur}
                  error={invalid}
                  onChange={onChange}
                  SelectProps={{
                    style: {
                      height: '48px',
                      color: 'red',
                      fontWeight: 'bold',
                    },
                    IconComponent: props => (
                      <ExpandMoreIcon {...props} sx={{ color: 'red !important', fontSize: '25px !important' }} />
                    ),
                    id: 'dgLookupLanguageCode',
                  }}
                  sx={{
                    width: '75px',
                    pt: '21.5px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderLeftWidth: '0.5px',
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                    },
                    color: 'red !important',
                  }}
                >
                  {adrLanguagesQuery?.isLoading && !adrLanguagesQuery?.isError && (
                    <MenuItem value={getValues('languageCode')}>
                      <Loader />
                    </MenuItem>
                  )}
                  {adrLanguagesQuery?.data?.languageCodes?.map(code => (
                    <MenuItem key={code} value={code}>
                      {code.toUpperCase()}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Box>
          <Box pt="21px">
            <FcpButton
              disabled={!formState.isDirty || !formState.isValid || formState.isSubmitting}
              variant="outlined"
              loading={formState.isSubmitting}
              type="submit"
              sx={{ height: '48px' }}
              id="dgLookupSubmit"
            >
              {t('general|labels|buttons|enter')}
            </FcpButton>
          </Box>
        </Box>
        {showValidationError && (
          <Alert severity="error" variant="outlined" id="dgLookupUnWithPsnError">
            {t('general|dangerousGoods|unWithPsnError')}
          </Alert>
        )}
      </Stack>

      {/* RESULT TABLE */}
      <DgItemsTable
        dgItems={showValidationError ? [] : dgItems}
        loading={searchResult.isFetching}
        setSortByUnNumberAsc={setSortByUnNumberAsc}
        sortByUnNumberAsc={sortByUnNumberAsc}
        onSelect={onSelect}
        dangerousGoodsExclusions={dangerousGoodsExclusions}
      />
    </Dialog>
  );
};

const DgItemsTable = ({
  dgItems,
  loading,
  setSortByUnNumberAsc,
  sortByUnNumberAsc,
  onSelect,
  dangerousGoodsExclusions,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {dgItems.length && !loading ? (
        <Box
          sx={{ p: '24px 24px 0 24px', display: 'flex', gap: DG_COLUMN_GAP, alignItems: 'center' }}
          id="dgLookupItemsTable"
        >
          <Box
            sx={{
              width: UN_NUMBER_WIDTH,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            UN No.
            <IconButton
              aria-label="sort"
              onClick={() => setSortByUnNumberAsc(prev => !prev)}
              color="primary"
              sx={{ p: 0 }}
              id="dgLookupTableSortByUnNumber"
            >
              {sortByUnNumberAsc ? <ArrowDropUp sx={{ fontSize: 30 }} /> : <ArrowDropDown sx={{ fontSize: 30 }} />}
            </IconButton>
          </Box>
          <Box>{t('general|dangerousGoods|searchDetailsColumn')}</Box>
        </Box>
      ) : null}
      <DialogContent
        sx={{
          borderTop: '1px solid #929292',
          borderBottom: '1px solid #929292',
        }}
      >
        {dgItems.length && !loading
          ? dgItems.map((dgItem, index) => {
              const isLastItem = dgItems.length - 1 === index;
              return (
                <DgRow
                  key={index}
                  unNumber={dgItem.unNumber}
                  psn={dgItem.psn}
                  adrClass={dgItem.class}
                  packagingGroup={dgItem.packingGroup}
                  comment={dgItem.comment}
                  preSelected={dgItem.preSelected}
                  onPreSelect={() => onSelect(dgItem.dgmId)}
                  sx={{
                    borderBottom: !isLastItem ? '1px solid #8F8F8F' : undefined,
                    mb: !isLastItem ? '16px' : undefined,
                    pb: !isLastItem ? '16px' : undefined,
                  }}
                  isExludedDangerousItem={isExcludedDangerousItem(dgItem, dangerousGoodsExclusions)}
                />
              );
            })
          : null}

        {dgItems.length === 0 && !loading ? (
          <Box
            id="dgLookupTableNoResultsFound"
            sx={{
              textAlign: 'center',
              mx: '32px',
              my: '64px',
            }}
          >
            {t('general|errors|No Results Found')}
          </Box>
        ) : null}

        {loading ? <Loader sx={{ my: '64px', mx: '16px' }} /> : null}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: 3,
        }}
      >
        <Typography variant="body1" id="dgLookupResultCounter">
          {t('general|labels|displayingNResults', { n: dgItems?.length ?? 0 })}
        </Typography>
      </DialogActions>
    </>
  );
};
