import { apiLinks } from '../../config/api-config';
import { rootApi } from './apiSlice';
import { GetCmsPhrasesParams, PhraseDto } from './fcpCms.types';

const cmsApi = rootApi.injectEndpoints({
  endpoints: builder => ({
    getCmsPhrases: builder.query<PhraseDto, GetCmsPhrasesParams>({
      query: ({ pageCode, lang, countryCode, isPreview = false }) => ({
        url: apiLinks.getCmsPhrases,
        params: { pageCode, lang, countryCode, isPreview },
      }),
      keepUnusedDataFor: 60 * 30,
    }),
  }),
});

export const { useGetCmsPhrasesQuery } = cmsApi;
